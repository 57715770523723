import {  useReducer } from 'react';
import { fetchingInitialState, fetchingReducerDataMap, runFetchingRequest } from './FetchingReducer';
import { uploadRadioTrackRequest, deleteTrackRequest } from '../Radio';
import { useAppContext } from '../../hooks/useAppContext';

const initialState = {
  ...fetchingInitialState
}

const radioReducerDataMap = {
  ...fetchingReducerDataMap,
}

const radioReducer = (state, action) => {
  const actionReduce = radioReducerDataMap[action.type];
  if (!actionReduce) return state;

  return actionReduce(state, action);
}

export function useRadioReducer() {
  const [ state, dispatch ] = useReducer(radioReducer, initialState);
  const { type } = useAppContext();

  const addTrack = async (file, station, name) => {
    if (!station || !file || !name) {
        return alert('Please fill all fields');
    }

    runFetchingRequest(dispatch, async () => {
      try {
        return await uploadRadioTrackRequest(type, file, station, name);
      } catch(err) {
          console.error('Err', err);
          alert('Problem adding new track.')
      }
    });
  }

  const deleteTrack = async (id) => {
    if (!id) {
        return alert('Please provide id for delete');
    }

    runFetchingRequest(dispatch, async (type) => {
      try {
        return await deleteTrackRequest({type, id});
      } catch(err) {
          console.error('Err', err);
          alert('Problem deleting track.')
      }
    });
}

  return {
    addTrack,
    deleteTrack,
    type,
    fetching: state.fetching,
  }
}