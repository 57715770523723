import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import { url } from '../Constants';

import { useSettings } from '../context/game-settings-context';

const Metaverse = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ data, setData ] = useState([]);
    const [ file, setFile ] = useState(null); 
    const [ isVideo, setIsVideo ] = useState(false);
    const [ order, setOrder ] = useState(1);
    const [ message, setMessage ] = useState('');
    const [ editing, setEditing ] = useState(false);
    const [ editingId, setEditingId ] = useState('')
    const [ editingFile, setEditingFile ] = useState(''); // string

    const getData = async () => {
        const token = localStorage.getItem('pcAdmin');
        const get = await fetch(`${url(type)}/metaverse/all`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        const json = await get.json();

        setData(json.data);
    }

    const addFile = async e => {
        e.preventDefault();

        const token = localStorage.getItem('pcAdmin');

        const formData = new FormData();

        formData.append('order', order);
        formData.append('isVideo', isVideo);
        formData.append('metaverse-image', file);

        const send = await fetch(`${url(type)}/metaverse/add`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: formData
        });

        const json = await send.json();

        if(json.success) {
            setIsVideo(false);
            setFile(null);
            setMessage('File added successfully.');
            setOrder(1);
            setData(json.data);
        } else {
            setMessage(json.message);
        }
    }

    useEffect(() => {
        getData();
    }, [type]); //eslint-disable-line
    
    useEffect(() => {
        if(message.length > 0) {
            setTimeout(() => {
                setMessage('');
            }, 3000);
        }
    }, [message]); //eslint-disable-line

    const deleteFileHandler = async id => {
        const token = localStorage.getItem('pcAdmin');
        const del = await fetch(`${url(type)}/metaverse/delete`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: JSON.stringify({ id })
        });

        const json = await del.json();

        if(json.success) getData();
    }

    const initEditHandler = _id => {
        const find = data.find(item => item._id === _id);
        setEditing(true);
        const { image, isVideo, order } = find;
        setEditingId(_id);
        setIsVideo(isVideo);
        setOrder(order);
        setEditingFile(image);
    }

    const resetEditState = () => {
        setEditing(false);
        setEditingFile('');
        setOrder(1);
        setEditingId('');
    }

    const editFile = async e => {
        e.preventDefault();
        const token = localStorage.getItem('pcAdmin');

        const formData = new FormData();

        formData.append('_id', editingId);
        formData.append('order', order);
        formData.append('isVideo', isVideo);
        formData.append('metaverse-image', file);

        const edit = await fetch(`${url(type)}/metaverse/edit`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: formData
        });

        const json = await edit.json();

        if(json.success) {
            setData(json.data);
            resetEditState();
        } else {
            setMessage('Edit fail')
        }
    }

    const { settings, setSettings } = useSettings();

    const [ websiteInputKey, setWebsiteImageInputKey ] = useState(Math.random());
    const [ websiteImage, setWebsiteImage ]= useState();

    const updateWebsiteImageHandler = (e) => {
        setWebsiteImage(e.target.files[0]);
    }

    const uploadWebsiteImageHandler = async () => {

        const formData = new FormData();

        formData.append('_id', settings._id);
        formData.append('image', websiteImage);

        const token = localStorage.getItem('pcAdmin');

        const update = await fetch(`${url(type)}/game-settings/website-graphic`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        const json = await update.json();

        const { data, success } = json;

        if(success) {
            setSettings(data);
            window.alert('Image added')
            setWebsiteImageInputKey(Math.random());
        }
    }

    const deleteWebsiteImageHandler = async (imageId) => {

        const token = localStorage.getItem('pcAdmin');

        const update = await fetch(`${url(type)}/game-settings/website-graphic`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: settings._id,
                imageId
            })
        });

        const json = await update.json();

        const { data, success } = json;

        if(success) {
            setSettings(data);
            window.alert('Image deleted');
        }
    }

    return (
        <Page title={!editing ? 'Add Metaverse' : 'Edit Metaverse'}>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div style={{ display: 'flex', alignItems: "center" }}>
                                <div style={{ width: '50%'}}>
                                    {!editing ? 'Add Metaverse' : 'Edit Metaverse'}
                                </div>
                                {editing && (
                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                        <p style={{ margin: 0, marginRight: '10px', padding: 0 }}>Editing</p>
                                        <button className="btn btn-primary" onClick={resetEditState}>Reset Edit</button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-5">
                                    <form onSubmit={!editing? addFile : editFile}>
                                        <div className="form-group">
                                            <label>File</label>
                                            {editingFile.length > 0 && (
                                                !isVideo ? <img src={editingFile} alt="" style={{ height: '100px', width: 'auto', objectFit: 'contain', display: "block" }} /> 
                                                : 
                                                <video src={editingFile} style={{ height: '100px', width: 'auto', display: 'block' }} autoPlay controls>
                                                    <source src={editingFile} />
                                                </video>
                                            )}
                                            <input className="form-control" type="file" value='' onChange={e => setFile(e.target.files[0])} /> 
                                            {/* {frontImage && <img src={URL.createObjectURL(frontImage)} alt="front" style={previewImgStyle} />} */}
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                                <input type="checkbox" checked={isVideo} onChange={e => setIsVideo(e.target.checked)} />Video ?
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Order
                                            </label>
                                            <input className="form-control" type="number" min="1" value={order} onChange={e => setOrder(parseInt(e.target.value))} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            {!editing ? 'Add File' : 'Edit File'}
                                        </button>
                                        <p>
                                            {message}
                                        </p>
                                    </form>
                                </div>
                                <div className="col-md-7">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>File</th>
                                                    <th>Order</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map(file => {
                                                    const { _id, image, isVideo, order } = file;
                                                    return (
                                                        <tr key={_id}>
                                                            <td>
                                                                {!isVideo ? <img src={image} alt="" style={{ height: '100px', width: 'auto', objectFit: 'contain' }} /> 
                                                                : 
                                                                <video src={image} style={{ height: '100px', width: 'auto' }} autoPlay controls>
                                                                    <source src={image} />
                                                                </video> }
                                                            </td>
                                                            <td>{order}</td>
                                                            <td>
                                                                <button className="btn btn-primary"onClick={() => initEditHandler(_id)}>
                                                                    Edit
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-danger" onClick={() => deleteFileHandler(_id)}>
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {settings && (
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                Website Images
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <p>Images list</p>
                                                {settings.websiteGraphic.map(item => {

                                                    return (
                                                        <div key={item._id} style={{ display: 'flex', marginBottom: 20 }}>
                                                            <div style={{ flex: 1, marginRight: 20 }}>
                                                                <img 
                                                                    src={item.location} 
                                                                    alt="" 
                                                                    style={{
                                                                        width: '100%',
                                                                        height: 'auto',
                                                                        objectFit: 'contain',
                                                                        marginBottom: 20
                                                                    }}
                                                                />
                                                            </div>
                                                            <button 
                                                                class="btn btn-danger"
                                                                type="button"
                                                                onClick={() => deleteWebsiteImageHandler(item._id)}
                                                            >   
                                                                Delete 
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                                <input 
                                                    key={websiteInputKey}
                                                    type="file"
                                                    style={{ marginBottom: 20 }}
                                                    onChange={updateWebsiteImageHandler}
                                                />
                                                <button class="btn btn-primary" type="button" onClick={uploadWebsiteImageHandler}>Add new Website image</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Page>
    )
}

export default Metaverse;