import React, { useContext } from 'react';
import { AppContext } from '../context/app-context';

import { POWERCORE, GRID } from '../Constants';

const Navbar = () => {

    const appContext = useContext(AppContext);

    const { state, changeType, logout } = appContext;
    const { type } = state;

    return (
        <nav className="navbar navbar-default navbar-cls-top " role="navigation" style={{ marginBottom: 0 }}>
            <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".sidebar-collapse">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <div className="navbar-brand">{type}</div> 
            </div>
            <div style={{ color: 'white', padding: '15px 50px 5px 50px', float: 'right', fontSize: '16px', display: 'flex', alignItems: 'center' }}> 
                <div className="form-group" style={{ marginRight: '10px', marginBlock: 0}}>
                    <label className="checkbox-inline">
                        <input type="checkbox" checked={type === POWERCORE} onChange={() => changeType(POWERCORE)} /> Powercore
                    </label>
                    <label className="checkbox-inline">
                        <input type="checkbox" checked={type === GRID} onChange={() => changeType(GRID)} /> Grid
                    </label>
                </div>
                <button className="btn btn-danger square-btn-adjust" onClick={logout}>
                    Logout  
                </button>
            </div>
        </nav> 
    )
}

export default Navbar;