import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import { url } from '../Constants';
import { useSettings } from '../context/game-settings-context';

const Settings = () => {
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ whitepaper, setWhitepaper ] = useState(null);
    const [ message, setMessage ] = useState(''); 

    const uploadWhitepaperHandler = async e => {
        e.preventDefault();
        setMessage('');

        if(!whitepaper || whitepaper.name !== 'Astro Cafe_Whitepaper.pdf') return setMessage('File name should be: Astro Cafe_Whitepaper.pdf');

        const formData = new FormData();

        formData.append('whitepaper', whitepaper);

        const send = await fetch(`${url(type)}/whitepaper/upload`, {
            method: 'POST',
            body: formData
        });

        const json = await send.json();

        setMessage(json.message);
    }

    useEffect(() => {
        if(message.length > 0) {
            setTimeout(() => {
                setMessage('');
            }, 3000);
        }
    }, [message]); 

    const { settings, setSettings } = useSettings();

    const token = useMemo(() => {
        return localStorage.getItem('pcAdmin')
    }, []);

    const setGameSettings = (type, value) => {
        setSettings(settings => {
            return {
                ...settings,
                challengeCutPercent: type === 'challengeCutPercent' ? value : settings.challengeCutPercent,
                challengeCreditMultiplier: type === 'challengeCreditMultiplier' ? value : settings.challengeCreditMultiplier,
                seasonQuestMultiplier: type === 'seasonQuestMultiplier' ? value : settings.seasonQuestMultiplier,
                infoText: type === 'infoText' ? value : settings.infoText
            }
        });
    }

    const updateSettingsHandler = async (e) => {
        e.preventDefault();

        const update = await fetch(`${url(type)}/game-settings`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...settings })
        });

        const json = await update.json();

        const { data, success } = json;

        if(success) {
            setSettings(data);
            window.alert('Game settings updated')
        }

    }

    const [ clubImage1, setClubImage1 ]= useState();
    const [ clubImage2, setClubImage2 ]= useState();
    const [ clubImage3, setClubImage3 ]= useState();

    const updateMainImageHandler = (e,room) => {

        if(room === 1) setClubImage1(e.target.files[0]);
        if(room === 2) setClubImage2(e.target.files[0]);
        if(room === 3) setClubImage3(e.target.files[0]);
    }

    const uploadMainImageHandler = async (room) => {

        const formData = new FormData();

        let img;

        if(room === 1) img = clubImage1;
        if(room === 2) img = clubImage2;
        if(room === 3) img = clubImage3;

        formData.append('_id', settings._id);
        formData.append('club-image', img);
        formData.append('room', `${room}`);

        const update = await fetch(`${url(type)}/game-settings/main-club-image`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        const json = await update.json();

        const { data, success } = json;

        if(success) {
            setSettings(data);
            window.alert(`Image for room ${room} updated`)
        }
    }

    const [ clubImage, setClubImage ]= useState();
    const [ clubImageInputKey, setClubImageInputKey ] = useState(Math.random());

    const updateClubImageHandler = (e) => {
        setClubImage(e.target.files[0]);
    }

    const uploadClubImageHandler = async () => {

        const formData = new FormData();

        formData.append('_id', settings._id);
        formData.append('club-image', clubImage);

        const update = await fetch(`${url(type)}/game-settings/club-image`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        const json = await update.json();

        const { data, success } = json;

        if(success) {
            setSettings(data);
            window.alert('Image added')
            setClubImageInputKey(Math.random());
        }
    }

    const deleteClubImageHandler = async (imageId) => {
        const update = await fetch(`${url(type)}/game-settings/club-image`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: settings._id,
                imageId
            })
        });

        const json = await update.json();

        const { data, success } = json;

        if(success) {
            setSettings(data);
            window.alert('Image deleted');
        }
    }

    return (
        <Page title='Settings'>
            <div className="panel panel-default">
                <div className="panel-heading">
                    Whitepaper
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>
                                Whitepaper name: Astro Cafe_Whitepaper.pdf
                            </p>
                            <form onSubmit={uploadWhitepaperHandler}>
                                <div className="form-group">
                                    <input type="file" className="form-control" onChange={e => setWhitepaper(e.target.files[0])} />
                                </div>
                                <button type="submit" className="btn btn-primary">Upload</button>
                                <p>
                                {message}
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {settings && (
                <div className="panel panel-default">
                    <div className="panel-heading">
                        Settings
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <form onSubmit={updateSettingsHandler}>
                                    <div className="form-group input-group">
                                        <label>Challenge Cut Percent</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            onChange={e => setGameSettings('challengeCutPercent', e.target.value)} 
                                            value={settings.challengeCutPercent}
                                        />
                                    </div>
                                    <div className="form-group input-group">
                                        <label>Challenge Credit Multiplier</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            onChange={e => setGameSettings('challengeCreditMultiplier', e.target.value)} 
                                            value={settings.challengeCreditMultiplier}
                                        />
                                    </div>
                                    <div className="form-group input-group">
                                        <label>Season Quest Multiplier</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            onChange={e => setGameSettings('seasonQuestMultiplier', e.target.value)} 
                                            value={settings.seasonQuestMultiplier}
                                        />
                                    </div>
                                    <div className="form-group input-group" style={{ width: '100%' }}>
                                        <label>Info text</label>
                                        <ReactQuill theme="snow" value={settings.infoText} onChange={value => setGameSettings('infoText', value)} />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {settings && (
                <>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Club Images
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            {[1, 2, 3].map(item => {

                                                return (
                                                    <div key={item} className='mb-5'>
                                                        <p>Room {item} image</p>
                                                        {settings.clubGraphic[`clubRoom${item}Image`] && (
                                                            <img 
                                                                src={settings.clubGraphic[`clubRoom${item}Image`].location} 
                                                                alt="" 
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto',
                                                                    objectFit: 'contain',
                                                                    marginBottom: 20
                                                                }}
                                                            />
                                                        )}
                                                        <input 
                                                            type="file"
                                                            onChange={e => updateMainImageHandler(e, item)}
                                                            style={{
                                                                marginBottom: 20
                                                            }}
                                                        />
                                                        <button class="btn btn-primary" type="button" onClick={() => uploadMainImageHandler(item)}>Update room {item} image</button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-6">
                                            <p>Images list</p>
                                            {settings.clubGraphic.list.map(item => {

                                                return (
                                                    <div key={item._id} style={{ display: 'flex', marginBottom: 20 }}>
                                                        <div style={{ flex: 1, marginRight: 20 }}>
                                                            <img 
                                                                src={item.location} 
                                                                alt="" 
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto',
                                                                    objectFit: 'contain',
                                                                    marginBottom: 20
                                                                }}
                                                            />
                                                        </div>
                                                        <button 
                                                            class="btn btn-danger"
                                                            type="button"
                                                            onClick={() => deleteClubImageHandler(item._id)}
                                                        >   
                                                            Delete 
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                            <input 
                                                key={clubImageInputKey}
                                                type="file"
                                                style={{ marginBottom: 20 }}
                                                onChange={updateClubImageHandler}
                                            />
                                            <button class="btn btn-primary" type="button" onClick={uploadClubImageHandler}>Add new Club image</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </Page>
    )
}

export default Settings;