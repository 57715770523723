import * as actionTypes from '../../context/ActionTypes';

export const fetchingInitialState = {
  fetching: false,
}

export const fetchingReducerDataMap = {
  [actionTypes.CHANGE_TYPE]: (state, action) => {
    return {
      ...state,
      type: action.newType
    }
  },
  [actionTypes.FETCHING]: (state, action) => {
    return {
      ...state,
      fetching: true
    }
  },
  [actionTypes.FETCHING_FINISHED]: (state, action) => {
    return {
      ...state,
      fetching: false
    }
  },
}

const initRequest = (dispatch) => dispatch({ type: actionTypes.FETCHING });

const endRequest = (dispatch) => dispatch({ type: actionTypes.FETCHING_FINISHED });

export const runFetchingRequest = (dispatch, requestCb) => {
  initRequest(dispatch);
  requestCb();
  endRequest(dispatch);
}