import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import {deleteRewardRequest, getRewardsRequest, uploadRewardFile} from "../effects/NftRewards";
import NFTRewardList from "../components/NFTRewardList";
import {reloadPage} from "../helpers/page";

const Settings = () => {
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ file, setFile ] = useState(null);
    const [ message, setMessage ] = useState('');
    const [ rewards, setRewards ] = useState([]);

    const uploadFileHandler = async e => {
        e.preventDefault();
        setMessage('');

        const formData = new FormData();
        formData.append('file', file);

        const sendData = await uploadRewardFile({type, formData})
        setMessage(sendData.message);
        if (sendData && sendData.data != null) {
            reloadPage();
        }
    }

    const getRewards = async () => {
        const data =  await getRewardsRequest(type);
        if (data) {
            setRewards(data);
        }
    }

    const onRewardDelete = async (id) => {
        const result = await deleteRewardRequest({type, id});
        if (result != null) {
            reloadPage();
        }
    }

    useEffect(() => {
        getRewards();
    }, [type]); //eslint-disable-line

    return (
        <Page title='Rewards'>
            <div className="panel panel-default">
                <div className="panel-heading">
                    NFT Rewards
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>
                                Upload rewards file
                            </p>
                            <form onSubmit={uploadFileHandler}>
                                <div className="form-group">
                                    <input type="file" className="form-control" onChange={e => setFile(e.target.files[0])} />
                                </div>
                                <button type="submit" className="btn btn-primary">Upload</button>
                                <p>
                                {message}
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    <NFTRewardList rewards={rewards} onDelete={onRewardDelete}/>
                </div>
            </div>
        </Page>
    )
}

export default Settings;
