import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AppContext } from './context/app-context';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

import Login from './pages/Login';
import Invites from './pages/Invites';
import Users from './pages/Users';
import Subscriptions from './pages/Subscriptions';
import Shop from './pages/Shop';
import Marketplace from './pages/Marketplace';
import Versions from './pages/Versions';
import Bugs from './pages/Bugs';
import Metaverse from './pages/Metaverse';
import Settings from './pages/Settings';
import Videos from "./pages/Videos";

import './assets/css/bootstrap.css';
import './assets/css/font-awesome.css';
import './assets/css/custom.css';
import './assets/css/admin.css';
import Promotions from "./pages/Promotions";
import PromotionDetails from "./pages/PromotionDetails";
import NftRewards from "./pages/NftRewards";
import UserGame from "./pages/UserGame";
import UsersCreateAdmin from "./pages/UsersCreateAdmin";
import Locations from "./pages/Locations";
import AddLocation from "./components/AddLocation";
import AddEvent from "./components/AddEvent";
import Events from "./pages/Events";
import DailyQuests from './pages/DailyQuests';
import Rooms from './pages/Rooms';
import Radio from './pages/Radio';

const App = () => {
    const appContext = useContext(AppContext);
    const { state, getUser, setTypeInit } = appContext;
    const { isAuth } = state;

    useEffect(() => {
        getUser();
        setTypeInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if(!isAuth) {
        return <Login />
    }

    return (
        <div id="wrapper">
            <Router>
            <Navbar />
            <Sidebar />
                <Switch>
                    <Route path='/' exact component={Users} />
                    <Route path='/invites' component={Invites} />
                    <Route path='/users/game/:address' component={UserGame} exact={true} />
                    <Route path='/users/admin/create' component={UsersCreateAdmin} exact={true} />
                    <Route path='/users' component={Users} />
                    <Route path='/subscriptions' component={Subscriptions} />
                    <Route path='/shop' component={Shop} />
                    <Route path='/marketplace' component={Marketplace} />
                    <Route path='/versions' component={Versions} />
                    <Route path='/promotions/:id' component={PromotionDetails} />
                    <Route path='/promotions' component={Promotions} />
                    <Route path='/bugs' component={Bugs} />
                    <Route path='/metaverse' component={Metaverse} />
                    <Route path='/settings' component={Settings} />
                    <Route path='/videos' component={Videos} />
                    <Route path='/nftRewards' component={NftRewards} />
                    <Route path='/radio' component={Radio} />

                    {/* <Route path='/locations/admin/create' component={AddLocation} exact={true} />
                    <Route path='/locations' component={Locations} exact={true} />
                    <Route path='/locations/:locationId/events' component={Events} exact={true} />
                    <Route path='/locations/event/create' component={AddEvent} exact={true} /> */}

                    <Route path='/daily-quests' component={DailyQuests} />
                    {/* <Route path='/challenges' component={Challenges} /> */}
                    <Route path='/rooms' component={Rooms} />
                </Switch>
            </Router>
        </div>
    )
}

export default App;
