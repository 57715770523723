import React, { useEffect, useState, useContext } from 'react';

import { AppContext } from '../../context/app-context';

import { url } from '../../Constants';

import { CarList, QuestTypes, secondsFormat } from '../DailyQuests/InnerTable'

const NewRoomModal = ({ close, date, _id, maps, addNewCallback }) => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ car, setCar ] = useState([]); 
    const [ mapsSelect, setMapsSelect ] = useState([]);
    const [ questTypes, setQuestTypes ] = useState([]);

    useEffect(() => {
        const mapCar = [...CarList].map((car, index) => {
            return {
                car,
                selected: index === 0 ? true : false
            }
        });

        setCar(mapCar);

        const mapMaps = [...maps].map((m, index) => {
            return {
                ...m,
                selected: index === 0 ? true : false
            }
        });

        setMapsSelect(mapMaps);

        const mapQuestTypes = [...QuestTypes].map((type, index) => {
            return {
                type,
                selected: index === 0 ? true : false
            }
        });

        setQuestTypes(mapQuestTypes);

    }, [ maps ]);

    const changeSelectHandler = (value, type) => {
        if(type === 'car') {

            setCar(cars => {
                return [...cars].map(car => {
                    return {
                        ...car,
                        selected: car.car === value ? true : false
                    }
                })
            });
        }

        if(type === 'maps') {
            setMapsSelect(maps => {
                return [...maps].map(m => {
                    return {
                        ...m,
                        selected: m._id === value ? true : false
                    }
                })
            });
        }

        if(type === 'VIPRequirement') {
            setVIPRequirement(value === 'true' ? true : false);
        }
    }

    // const [ room, setRoom ] = useState(1);
    const [ roomType, setRoomType ] = useState(1);
    const [ capacity, setCapacity ] = useState(1);
    const [ music, setMusic ] = useState(1);
    const [ XPRequirement, setXPRequirement ] = useState(0);
    const [ VIPPercentage, setVIPPercentage ] = useState(1);
    const [ VIPRequirement, setVIPRequirement ] = useState(false);
    const [ ticketPrice, setTicketPrice ] = useState(0);
    const [ laps, setLaps ] = useState(1);
    const [ overallTime, setOverallTime ] = useState(0);
    const [ totalAttempts, setTotalAttempts ] = useState(0);
    const [ color, setColor ] = useState(0);
    const [ teeShirtImage, setTeeShirtImage ] = useState(0);
    // const [ maxNumberOfCollectibles, setMaxNumberOfCollectibles ] = useState(0);
    const [ rewardMultiplier, setRewardMultiplier ] = useState(0);

    const submitHandler = async e => {
        e.preventDefault();

        const token = localStorage.getItem('pcAdmin');

        const findCar = car.find(item => item.selected);
        const findMap = mapsSelect.find(item => item.selected);

        const bodyObj = {
            _id, 
            roomType,
            capacity,
            music,
            xpRequirement: XPRequirement,
            vipPercentage: VIPPercentage,
            vipRequirement: VIPRequirement,
            ticketPrice,
            map: findMap._id,
            car: findCar.car,
            laps,
            overallTime,
            totalAttempts,
            rewardMultiplier,
            teeShirtImage,
            color
        }

        const add = await fetch(`${url(type)}/daily-rooms/add`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyObj)
        });

        const json = await add.json();

        if(json.success) {
            addNewCallback(json.data);
            close();
        } else {
            window.alert(json.message);
        }
    }

    return (
        <div style={{ 
            width: "100vw",
            height: '100vh',
            overflowY: 'scroll',
            background: 'rgba(0,0,0, 0.95)',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: '100'
        }}>
            <div onClick={close}>
                <div className="close"></div>
            </div>
            <div className="container">
                <div className="row" style={{ display: 'flex', justifyContent: 'center', margin: '20px 0', textAlign: 'center'}}>
                    <div className="col-md-10">
                        <h2 style={{ marginBottom: '30px'}}>Add new room for date {date}</h2>
                        <form onSubmit={submitHandler}>
                            {/* <Input>
                                <label>Room</label>
                                <select className="form-control" onChange={(e) => setRoom(parseFloat(e.target.value))}>
                                    <option value='1' selected={room === 1}>1</option>
                                    <option value='2' selected={room === 2}>2</option>
                                    <option value='3' selected={room === 3}>3</option>
                                    <option value='4' selected={room === 4}>4</option>
                                    <option value='5' selected={room === 5}>5</option>
                                    <option value='6' selected={room === 6}>6</option>
                                    <option value='7' selected={room === 7}>7</option>
                                    <option value='8' selected={room === 8}>8</option>
                                </select>
                            </Input> */}
                            <Input>
                                <label>Room Type</label>
                                <select className="form-control" onChange={(e) => setRoomType(parseFloat(e.target.value))}>
                                    <option value='1' selected={roomType === 1}>1</option>
                                    <option value='2' selected={roomType === 2}>2</option>
                                    <option value='3' selected={roomType === 3}>3</option>
                                </select>
                            </Input>
                            <Input>
                                <label>Capacity</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    max='200'
                                    step={1}
                                    value={capacity} 
                                    onChange={(e) => setCapacity(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Music</label>
                                <select className="form-control" onChange={(e) => setMusic(parseFloat(e.target.value))}>
                                    <option value='1' selected={music === 1}>1</option>
                                    <option value='2' selected={music === 2}>2</option>
                                    <option value='3' selected={music === 3}>3</option>
                                </select>
                            </Input>
                            <Input>
                                <label>XP Requirement</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    max='100000'
                                    step={1}
                                    value={XPRequirement} 
                                    onChange={(e) => setXPRequirement(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>VIP %</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={VIPPercentage} 
                                    onChange={(e) => setVIPPercentage(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>VIP Requirement</label>
                                <select className="form-control" onChange={(e) => changeSelectHandler(e.target.value, 'VIPRequirement')}>
                                    <option value={true} selected={VIPRequirement === true}>Yes</option>
                                    <option value={false} selected={VIPRequirement === false}>No</option>
                                </select>
                            </Input>
                            <Input>
                                <label>Ticket Price</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={ticketPrice} 
                                    onChange={(e) => setTicketPrice(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Select Map</label>
                                <select className="form-control" onChange={(e) => changeSelectHandler(e.target.value, 'maps')}>
                                    {mapsSelect.map(map => <option key={map._id} value={map._id}>{map.name}</option>)}
                                </select>
                            </Input>
                            <Input>
                                <label>Select Car</label>
                                <select className="form-control" onChange={(e) => changeSelectHandler(e.target.value, 'car')}>
                                    {car.map(item => <option key={item.car} value={item.car}>{item.car}</option>)}
                                </select>
                            </Input>
                            <Input>
                                <label>Total Laps</label>
                                <select className="form-control" onChange={(e) => setLaps(parseFloat(e.target.value))}>
                                    <option value={1} selected={laps === 1}>1</option>
                                    <option value={2} selected={laps === 2}>2</option>
                                    <option value={3} selected={laps === 3}>3</option>
                                    <option value={4} selected={laps === 4}>4</option>
                                    <option value={5} selected={laps === 5}>5</option>
                                </select>
                            </Input>
                            <Input>
                                <label>Overall Time {secondsFormat(overallTime)}</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    value={overallTime} 
                                    onChange={(e) => setOverallTime(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Total Attempts</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    value={totalAttempts} 
                                    onChange={(e) => setTotalAttempts(parseFloat(e.target.value))} 
                                />
                            </Input>
                            {/* <Input>
                                <label>Max Number Of Collectibles </label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    value={maxNumberOfCollectibles} 
                                    onChange={(e) => setMaxNumberOfCollectibles(parseFloat(e.target.value))} 
                                />
                            </Input> */}
                            <Input>
                                <label>Reward Multiplier </label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={rewardMultiplier} 
                                    onChange={(e) => setRewardMultiplier(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Color </label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={color} 
                                    onChange={(e) => setColor(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>T-Shirt Image</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={teeShirtImage} 
                                    onChange={(e) => setTeeShirtImage(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <button type="submit" class="btn btn-default">Submit Button</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Input = ({ children }) => (
    <div className="row" style={{ display: 'flex', justifyContent: 'center'}}>
        <div className="col-md-4">
            <div class="form-group" style={{ marginBottom: '20px'}}>
                {children}
            </div>
        </div>
    </div>
)

export default NewRoomModal;