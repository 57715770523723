import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/app-context';

import { url } from '../Constants';
import LoadingBtn, {LoadingBtnType} from "./LoadingBtn";
import {clearOldNftAddresses} from "../effects/Nft";

const NFTList = props => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ NFTs, setNFTs ] = useState([]);
    const [ filtered, setFiltered ] = useState([]);
    const [ message, setMessage ] = useState('');
    const [ clearOldLoading, setClearOldLoading] = useState(false);
    const [pastNftAddress, setPastNftAddress] = useState('');

    useEffect(() => {
        const getNFTS = async () => {
            const get = await fetch(`${url(type)}/nft/all`);
            const json = await get.json();
            const { success, data } = json;
            if(success) {
                setNFTs(data);
            }
        }

        getNFTS();
    }, [type]);

    useEffect(() => {
        setFiltered(NFTs);
    }, [NFTs]);

    const deleteHandler = async id => {
        const del = await fetch(`${url(type)}/nft/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        });

        const json = await del.json();

        if(json.success) {
            const filter = NFTs.filter(nft => nft._id !== id);
            setNFTs(filter);
        }
    }

    const clearOldNfts = async () => {
        setClearOldLoading(true)
        const data = await clearOldNftAddresses(type, pastNftAddress);
        if (data) {
            setMessage('Cleared old nft addresses and set past to desired');
            setClearOldLoading(false);
        }
    }

    const filterNFTsHandler = e => {
        const value = e.target.value;
        const nfts = [...NFTs];
        const filter = nfts.filter(nft => {
            const collection = nft.collections.main;
            return value === 'all' ? collection : collection === value;
        });
        setFiltered(filter);
    }

    const onPastNftValueChange = (e) => {
        setPastNftAddress(e.target.value);
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                List of NFTS
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="form-group">
                        <div className="col-lg-12">
                            <select onChange={filterNFTsHandler} className="form-control" style={{ marginBottom: '15px'}}>
                                <option value="all">
                                    All
                                </option>
                                <option value="Totems">
                                    Totems
                                </option>
                                <option value="Martin-Tan">
                                    Martin Tan
                                </option>
                                <option value="GridZone">
                                    GridZone
                                </option>
                                <option value="Legendary">
                                    Legendary
                                </option>
                                <option value="Classic">
                                    Classic
                                </option>
                                <option value="Cars">
                                    Cars
                                </option>
                                <option value="Bikes">
                                    Bikes
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                {message ? <p>
                    {message}
                </p>: null }
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Order</th>
                                <th>Available</th>
                                <th>Creator</th>
                                {/* <th>Contract Address</th> */}
                                <th>Owner</th>
                                {/* <th>Owner Address</th> */}
                                <th>Collection</th>
                                {/* <th>Token ID</th> */}
                                {/* <th>Network</th> */}
                                {/* <th>NYM Power Bonus</th> */}
                                <th>Model Id</th>
                                <th>Price</th>
                                <th>
                                    Edit
                                </th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.map(nft => {
                                const { _id, name, order, available, totalToMint, creator, owner, collections, price, img, modelId } = nft;
                                const collection = collections.main;
                                let image = null;
                                if (img && img.preview) image = img.preview.front;

                                return (
                                    <tr key={_id}>
                                        <td>{name}</td>
                                        {image ?<td>
                                            <img src={image} alt={name} style={{ height: '40px', width: '40px', borderRadius: '50%', objectFit: 'cover' }} />
                                        </td>: <td />}
                                        <td>{order}</td>
                                        <td>{available} / {totalToMint}</td>
                                        <td>{creator}</td>
                                        {/* <td>{contractAddress}</td> */}
                                        <td>{owner}</td>
                                        {/* <td>{ownerAddress}</td> */}
                                        <td>{collection}</td>
                                        {/* <td>{tokenID}</td> */}
                                        {/* <td>{network}</td> */}
                                        {/* <td>{nymPowerBonus}</td> */}
                                        <td>{modelId}</td>
                                        <td>{price}</td>
                                        <td>
                                            <Link className="btn btn-primary" to={{
                                                pathname: '/marketplace',
                                                state: {
                                                    edit: true,
                                                    nft
                                                }
                                            }}>
                                                Edit
                                            </Link>
                                        </td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => deleteHandler(_id)}>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <input style={{width: '35%'}} placeholder={'Enter past nft address'} type={'text'} value={pastNftAddress} onChange={onPastNftValueChange} />
                    <div style={{width: '35%'}}>
                        <LoadingBtn  type={LoadingBtnType.Warning} title={'Clear old'} loading={clearOldLoading} width={6} onClickChange={clearOldNfts} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default NFTList;
