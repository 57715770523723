import React from 'react';
import {Loader} from "./Loader";

export const LoadingBtnType = Object.freeze({ Primary:"Primary", Danger:"Danger",
    Warning: "Warning", Default: "Default"});

const LoadingBtn = ({ title, loading, width, onClickChange, type }) => {
    const getBtnType = (type) => {
        switch(type) {
            case LoadingBtnType.Primary:
                return 'btn-primary';
            case LoadingBtnType.Danger:
                return 'btn-danger';
            case LoadingBtnType.Warning:
                return 'btn-warning';
            case LoadingBtnType.Default:
                return 'btn-default';
            default:
                return null;
        }

    }

    const btnTypeClass = getBtnType(type);

    return (
        <div style={{position: 'relative'}}>
            <button type="button" className={`btn ${btnTypeClass} col-md-${width} add-video-btn`}
                    disabled={loading} onClick={onClickChange}>{title}</button>
            {loading ? <Loader /> : null}
        </div>
    )
}

export default LoadingBtn;
