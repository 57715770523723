import {fetchGetData, fetchPostJson} from "../helpers/fetchWrapper";

export const getVersionsRequest = async (type) => {
    return fetchGetData(type, 'version/all', 'GET');
}

export const getAppTypesRequest = async (type) => {
    return fetchGetData(type, 'version/appTypes', 'GET');
}

export const addVersionRequest = async (type, payload) => {
    await fetchPostJson(type, 'version/add',  payload);
}