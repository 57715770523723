import React from 'react';

import Page from '../components/Page';

const Shop = () => {

    return (
        <Page title='Shop'>

        </Page>
    )
}

export default Shop;