import React, { createContext, useReducer } from 'react';

import * as actionTypes from './ActionTypes';

import {GRID, url} from '../Constants';
import {
    addVideoRequest,
    deleteVideoRequest, editMultipleVideoRequest,
    getVideoStreamingLink,
    uploadVideoImageRequest,
    uploadVideoRequest
} from "../effects/Videos";
import {addPromotionRequest, deletePromotionRequest} from "../effects/Promotions";
import {fetchPostJson} from "../helpers/fetchWrapper";

export const AppContext = createContext({});

const backendTypeStorageKey = 'backendType';

const initialState = {
    type: localStorage.getItem(backendTypeStorageKey) || GRID,
    fetching: false,
    isAuth: false
}

const fetchingReducer = (state, action) => {
    switch(action.type) {
        case actionTypes.CHANGE_TYPE:
            return {
                ...state,
                type: action.newType
            }
        case actionTypes.FETCHING:
            return {
                ...state,
                fetching: true
            }
        case actionTypes.FETCHING_FINISHED:
            return {
                ...state,
                fetching: false
            }
        default:
            return state;
    }
}

const appReducer = (state, action) => {
    switch(action.type) {
        case actionTypes.CHANGE_TYPE:
            return {
                ...state,
                type: action.newType
            }
        case actionTypes.FETCHING:
            return {
                ...state,
                fetching: true
            }
        case actionTypes.FETCHING_FINISHED:
            return {
                ...state,
                fetching: false
            }
        case actionTypes.LOGIN:
            return {
                ...state,
                fetching: false,
                isAuth: true
            }
        case actionTypes.LOGOUT:
            return {
                ...state,
                fetching: false,
                isAuth: false
            }
        default:
            return state;
    }
}

const ContextProvider = props => {

    const [ state, dispatch ] = useReducer(appReducer, initialState);
    const { type } = state;

    const changeType = type => {
        localStorage.setItem(backendTypeStorageKey, type);
        dispatch({ type: actionTypes.CHANGE_TYPE, newType: type });
    }

    const initRequest = () => dispatch({ type: actionTypes.FETCHING });

    const endRequest = () => dispatch({ type: actionTypes.FETCHING_FINISHED });

    const getUser = async () => {
        initRequest();
        try {
            const token = localStorage.getItem('pcAdmin');

            // if(token != null) return dispatch({ type: actionTypes.LOGIN });

            const res = await fetch(`${url(type)}/auth/user`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if(res.status === 404 || res.status === 401) {
                return dispatch({ type: actionTypes.LOGOUT });
            }

            const json = await res.json();
            const { success, data } = json;
            if(success) {
                const { user } = data;
                const { userType } = user;
                if(userType === 'admin') dispatch({ type: actionTypes.LOGIN });
            } else {
                dispatch({ type: actionTypes.LOGOUT });
            }
        } catch(err) {
            dispatch({ type: actionTypes.LOGOUT });
        }
    }

    const setTypeInit = () => {
        const backendType = localStorage.getItem(backendTypeStorageKey);
        if (backendType) {
            changeType(backendType);
        }
    }

    const login = async (email, password) => {
        initRequest();
        try {

            /*if(email === 'maxx' && password === 'powercore202020') {
                dispatch({ type: actionTypes.LOGIN });
                localStorage.setItem('pcAdmin', 'XyZpoM');
            }*/

            const json = await fetchPostJson(type, `auth/login`, { 
                email, password
            });

            const { success, data } = json;

            if(success && data.user.userType === 'admin') {
                const { authToken, refreshToken } = data.token;
                localStorage.setItem('pcAdmin', authToken);
                localStorage.setItem('pcRefresh', refreshToken);
                dispatch({ type: actionTypes.LOGIN });
            } else {
                window.alert('fail')
            }
        } catch(err) {
            console.error('Login error: ', err);
            dispatch({ type: actionTypes.LOGOUT });
        }
    }

    const logout = () => {
        dispatch({ type: actionTypes.LOGOUT });
        localStorage.removeItem('pcAdmin');
    }

    const addVideo = async (description, name, videoFile, imageFile, groupName, cbs = {}) => {
        if (!description || !name || !videoFile || !imageFile) {
            return alert('Please fill all fields');
        }

        try {
            initRequest();

            if(cbs.videoUploadStart) cbs.videoUploadStart();
            const videoUploadResult = await uploadVideoRequest(type, videoFile);
            if(cbs.videoImageUploadStart) cbs.videoImageUploadStart();
            const videoImageUploadResult = await uploadVideoImageRequest(type, imageFile);
            if(cbs.videoStreamingProcessingStart) cbs.videoStreamingProcessingStart();
            const videoStreamingLink = await getVideoStreamingLink(type, videoUploadResult);
            const videoAdded = await addVideoRequest({type, name, description, url: videoStreamingLink,
                image: videoImageUploadResult, groupName, sourceUrl: videoUploadResult});
            endRequest();
            return videoAdded;
        } catch(err) {
            endRequest();
            alert('Problem adding new video, check your video data.');
        }
    }

    const deleteVideo = async (id) => {
        if (!id) {
            return alert('Please provide id for delete');
        }

        try {
            initRequest();

            const videoDeleted = await deleteVideoRequest({type, id});
            endRequest();
            return videoDeleted;
        } catch(err) {
            endRequest();
            alert('Problem deleting new video, try later.');
        }
    }

    const updateVideos = async (videos) => {
        if (!videos) {
            return alert('Please provide videos for update');
        }

        try {
            initRequest();

            const videosUpdated = await editMultipleVideoRequest({type, videos});
            endRequest();
            return videosUpdated;
        } catch(err) {
            endRequest();
            alert('Problem updating videos, try later.');
        }
    }

    const addPromotion = async (description, channel, postId, maxEligible) => {
        if (!description || !channel || !postId) {
            return alert('Please fill all fields');
        }

        try {
            return await addPromotionRequest({type, channel, description, postId, maxEligible});
        } catch(err) {
            console.error('Err', err);
            alert('Problem adding new promotion. Check if channel has already active promotion')
        }
    }

    const deletePromotion = async (id) => {
        if (!id) {
            return alert('Please provide id for delete');
        }

        try {
            initRequest();
            const deleted = await deletePromotionRequest({type, id});
            endRequest();
            return deleted;
        } catch(err) {
            endRequest();
            alert('Problem deleting new video, try later.');
        }
    }

    return (
        <AppContext.Provider value={{
            state,
            changeType,
            getUser,
            login,
            logout,
            addVideo,
            deleteVideo,
            updateVideos,
            addPromotion,
            deletePromotion,
            setTypeInit
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default ContextProvider;