import DeleteBtn from "./DeleteBtn";

export function SharedTable({ headers, values, onDelete }) {
  return <table className="table table-bordered videos">
    <thead>
    <tr>
        <th />
        {
          headers.map((header, index) => {
            return <th key={index}>{header}</th>
          })
        }
        <th />
    </tr>
    </thead>
    <tbody>
        {
          values.map((value) => {
            const { _id } = value;
            return (
                    <tr>
                        <td><i className='fa fa-bars'/></td>
                        {
                          Object.keys(value).map((key, index)=> {
                            return <td key={index}>{value[key]}</td>
                          })
                        }
                        <td ><DeleteBtn id={_id} onClickChange={onDelete} /></td>
                    </tr>
                    )}
            )
        }
    </tbody>
  </table>
}