import {authFetch} from "../helpers/fetchWrapper";

export const clearOldNftAddresses = async (type, pastId) => {
    let url = 'nft/clear';
    if (pastId) {
        url += `?pastAddressId=${pastId}`;
    }
    const json = await authFetch(type, url, 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}
