import React, {useState, useContext, useEffect} from 'react'

import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import {Link, useParams} from "react-router-dom";

import { url } from '../Constants';

import LoadingBtn, {LoadingBtnType} from "../components/LoadingBtn"; 
import Transactions from '../components/Transactions';

export const GuestGameTag = 'guest';

const UserGame = () => {
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;
    let { address } = useParams();
    
    const [ user, setUser ] = useState(null);
    const [ maps, setMaps ] = useState([]);

    useEffect(() => {
        const getAddress = async () => {
            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/user/${address}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const json = await get.json();
            const { data } = json;

            setUser(data);
        }

        getAddress();

        const getMaps = async () => {
            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/maps/user`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const json = await get.json();
            const { data } = json;

            setMaps(data);
        }

        getMaps();
    }, [ type, address ]);

    return (
        <Page title='User game info'>
            <div className="panel panel-default">
                <div className="panel-body">
                    <Link to="/users">Back</Link>
                    {user && (
                        <div className="row">
                            <div className="col-md-12">
                                <p className="col-md-2 color-primary">Username:</p>
                                <p className="col-md-2 color-primary">{user.userId?.userName}</p>
                            </div>
                            <div className="col-md-12">
                                <p className="col-md-2 color-primary">Address:</p>
                                <p className="col-md-2 color-primary">{user.address}</p>
                            </div>
                            <div className="col-md-12">
                                <p className="col-md-2 color-primary">Credit:</p>
                                <p className="col-md-2 color-primary">{user.credit}</p>
                            </div>
                            <div className="col-md-12">
                                <p className="col-md-2 color-primary">XP:</p>
                                <p className="col-md-2 color-primary">{user.userId?.XP}</p>
                            </div>
                            <div className="col-md-12">
                                <p className="col-md-2 color-primary">Cars:</p>
                                <p className="col-md-2 color-primary">{user.userId?.carList.map(car => (
                                    <span>
                                        {car}, <span> </span>
                                    </span>
                                ))}</p>
                            </div>
                            <div className="col-md-12">
                                <p className="col-md-2 color-primary">Maps:</p>
                                <p className="col-md-2 color-primary">{maps.map(map => (
                                    <span>
                                        {map.name}, <span> </span>
                                    </span>
                                ))}</p>
                            </div>
                        </div>
                        
                    )}
                </div>
            </div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <h4>Credit</h4>
                    <p>add credit</p>
                </div>
            </div>
            {user && <Transactions _id={user._id} />}
        </Page>
    )
}

export default UserGame;
