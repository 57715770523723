import React from 'react';

const Page = ({ children, title }) => (
    <div id="page-wrapper" >
        <div id="page-inner">
            <div className="row">
                <div className="col-md-12">
                    {typeof title === 'string' ? <h2>{title}</h2> : title}  
                </div>
            </div>
            <hr />
            {children}
        </div>
    </div>
)

export default Page;