import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';

import { url } from '../Constants';

import { CopyIcon } from '../components/Icons';
import {Link, useHistory} from "react-router-dom";
import getDate from '../helpers/getDate';

const Users = () => {
    const history = useHistory();
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ users, setUsers ] = useState([]);
    const [ page, setPage ] = useState(0);
    const [ perPage, setPerPage ] = useState(50); 
    const [ haveMore, setHaveMore ] = useState(true);
    const [ count, setCount ] = useState(null);

    const fetchCount = useCallback(() => {
        const fetchUsers = async () => {

            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/user/count`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const json = await get.json();
            const { data } = json;

            setCount(data);
        }

        fetchUsers();
    }, [ type ]); 

    useEffect(fetchCount, [ fetchCount ]);

    const getUsers = useCallback(() => {
        const fetchUsers = async () => {

            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/user/all?page=${page}&perPage=${perPage}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const json = await get.json();
            const { data } = json;

            setUsers(users => {

                return [...users, ...data];
            });

            setHaveMore(data.length >= perPage ? true : false);
        }

        fetchUsers();
    }, [ type, page, perPage ]);

    useEffect(getUsers, [ getUsers ]);

    const copyAddressToClipboard = value => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        window.alert('Copied!');

        return true;
    };

    const goToGame = (address) => {
        history.push(`users/game/${address}`);
    }

    if (!users) {
        return <div>Loading</div>
    }

    const getCount = () => {
        let total = 0;

        if(count) {
            Object.keys(count).forEach(key => {
                const num = count[key];

                total += num;
            })
        }

        return total;
    }

    return (
        <Page title='Users'>
            <div className="panel panel-default">
                <div className="panel-heading" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    {/* <p style={{marginRight: 'auto'}}>Total users : {users.length}</p> */}
                    <p><Link to={'/users/admin/create'}>Add admin</Link></p>
                </div>
                {count && (
                    <div className="panel-body">
                        <h4>There are total <strong>{getCount()}</strong> users in database.</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Level 1</th>
                                        <th>Level 2</th>
                                        <th>Level 3</th>
                                        <th>Level 4</th>
                                        <th>Level 5</th>
                                        <th>Level 6</th>
                                        <th>XP 1400 To 1699</th>
                                        <th>XP 1700 To 1999</th>
                                        <th>XP 2000 To 2299</th>
                                        <th>XP 2300 To 2599</th>
                                        <th>XP 2600 To 2999</th>
                                        <th>XP 3000+</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{count.level1}</td>
                                        <td>{count.level2}</td>
                                        <td>{count.level3}</td>
                                        <td>{count.level4}</td>
                                        <td>{count.level5}</td>
                                        <td>{count.level6}</td>
                                        <td>{count.range1400To1699}</td>
                                        <td>{count.range1700To1999}</td>
                                        <td>{count.range2000To2299}</td>
                                        <td>{count.range2300To2599}</td>
                                        <td>{count.range2600To2999}</td>
                                        <td>{count.over3000}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                <div className="panel-body">
                    <h4>Users:</h4>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>Credit</th>
                                    <th>Public Address</th>
                                    <th>Created</th>
                                    <th>Last Login</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => {
                                    const { _id, userName, address, userId, credit, createdAt, lastLoginTime } = user;

                                    return (
                                        <tr key={_id}>
                                            <td>{userId?.userName}</td>
                                            <td>{credit}</td>
                                            <td>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>{address}</span>
                                                    {address && (
                                                        <div className="copy-icon" onClick={() => copyAddressToClipboard(address)}>
                                                            <CopyIcon />
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                            <td>{getDate(createdAt)}</td>
                                            <td>{getDate(lastLoginTime)}</td>
                                            <td>
                                                {userId && (
                                                    <div style={{ display: 'flex' }}>
                                                        <button className="btn btn-info" onClick={() => goToGame(address)}>
                                                            Details
                                                        </button>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {haveMore && (
                            <p>
                                <button
                                    onClick={() => setPage(page => page + 1)}
                                >
                                    Load More
                                </button>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Users;
