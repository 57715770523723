import {url} from "../Constants";

export const authFetch = async (type, relativeUrl, method, payload) => {
    const token = localStorage.getItem('pcAdmin');
    let headers = {
        'Authorization': `Bearer ${token}`,
    };

    if (!(payload instanceof  FormData)) {
        headers = {
            ...headers,
            'Content-Type': 'application/json; charset=utf-8'
        }
    }
    const res = await fetch(`${url(type)}/${relativeUrl}`, {
        method,
        headers,
        body: payload
    });

    if(res.status === 404 || res.status === 401) {
        console.error('Bad response: ', res)
        throw res;
    }

    return await res.json();
}

export const fetchGet = async (type, relativeUrl) => {
    return authFetch(type, relativeUrl, 'GET');
}

export const fetchGetData = async (type, relativeUrl) => {
    return authFetch(type, relativeUrl, 'GET')
        .then((res) => res.data);
}

export const fetchPost = async (type, relativeUrl, payload) => {
    return authFetch(type, relativeUrl, 'POST', (payload));
}

export const fetchPostJson = async (type, relativeUrl, payload) => {
    return fetchPost(type, relativeUrl, JSON.stringify(payload));
}

export const fetchPutJson = async (type, relativeUrl, payload) => {
    return fetchPut(type, relativeUrl, JSON.stringify(payload));
}

export const fetchPut = async (type, relativeUrl, payload) => {
    return authFetch(type, relativeUrl, 'PUT', payload);
}

export const fetchDelete = async (type, relativeUrl) => {
    return authFetch(type, relativeUrl, 'DELETE');
}
