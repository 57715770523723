import React from "react";
import {SharedTable} from "./SharedTable";

const headers = ['Test', 'One']

const RadioList = ({tracks, onDeleteVideo}) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                List of radio tracks
            </div>
            <div className="panel-body">
                <div className="table-responsive">
                    <SharedTable headers={headers} values={tracks}></SharedTable>
                </div>
            </div>
        </div>
    )
}

export  default RadioList;
