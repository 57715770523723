import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/app-context';

import { url } from '../Constants';
import getDate from '../helpers/getDate';

const NFTTransactions = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;
    
    const [ transactions, setTransactions ] = useState([]);

    useEffect(() => {
        const getTransactions = async () => {
            const get = await fetch(`${url(type)}/nft/transactions`);
            const json = await get.json();
            const { success, data } = json;
            if(success) {
                setTransactions(data);
            }
        }

        getTransactions();
    }, [type]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                NFT Transactions
            </div>
            <div className="panel-body">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>NFT</th>
                                <th>User</th>
                                <th>Quantity</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction, index) => {
                                const { user, nft, quantity, createdAt } = transaction;

                                return (
                                    <tr key={index}>
                                        <td>{user.userName}</td>
                                        <td>{nft.name}</td>
                                        <td>{quantity}</td>
                                        <td>{getDate(createdAt)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default NFTTransactions;