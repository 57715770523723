import React from "react";
import DeleteBtn from "./DeleteBtn";

const NFTRewardList = ({rewards, onDelete}) => {
    return (
        <div className="panel-default">
            <div className="panel-heading">
                List of rewards
            </div>
            <div className="panel-body">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Verifier Address</th>
                            <th>Account</th>
                            <th>Signature</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {rewards.map((item) => {
                            const { _id, account, signature, contractAddress } = item;
                            return (
                                <tr key={_id}>
                                    <th>{contractAddress}</th>
                                    <th>{account}</th>
                                    <th>{signature}</th>
                                    <td >
                                        <DeleteBtn id={_id} onClickChange={onDelete} />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export  default NFTRewardList;
