import React, { useState } from 'react';

import InputContainer from '../DailyQuests/InputContainer';
import { CarList, secondsFormat } from '../DailyQuests/InnerTable';

import NewRoomModal from './NewRoomModal';

const tdStyle = {
    background: '#2c2e33' 
}

const InnerTable = ({ rooms, maps, changeInputHandler, editQuestHandler, date, _id, addNewCallback, deleteRoom }) => {

    const [ showModal, setShowModal ] = useState(false);

    const deleteQuestHandler = (_id) => {
        const confirm = window.confirm('Are you sure you want to delete this room');

        if(confirm) {
            const filter = [...rooms].filter(room => room._id !== _id);

            deleteRoom(filter);
        }
    }

    return (
        <>
            {showModal && <NewRoomModal close={() => setShowModal(false)} date={date} _id={_id} maps={maps} addNewCallback={addNewCallback} />}
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Room Type</th>
                            <th>Capacity</th>
                            <th>Music</th>
                            <th>XP Requirement</th>
                            <th>VIP %</th>
                            <th>VIP Requirement</th>
                            <th>Chip Price</th>
                            <th>Map</th>
                            <th>Vehicle</th>
                            <th>Laps</th>
                            <th>Overall Time</th>
                            <th>Total Attempts</th>
                            <th>Reward Multiplier</th>
                            <th>Color</th>
                            <th>T-Shirt Color</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rooms.map(quest => {

                            const { 
                                _id,
                                room, 
                                roomType,
                                capacity,
                                music,
                                xpRequirement,
                                vipPercentage,
                                vipRequirement,
                                ticketPrice,
                                type, 
                                map, 
                                laps, 
                                overallTime, 
                                car, 
                                editing, 
                                totalAttempts,  
                                rewardMultiplier,
                                maxNumberOfCollectibles,
                                color,
                                teeShirtImage
                            } = quest;

                            const findMap = maps.find(m => m._id === map._id);

                            return (
                                <tr key={_id}>
                                    {/* <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'room')}>
                                                <option value='1' selected={room === 1}>1</option>
                                                <option value='2' selected={room === 2}>2</option>
                                                <option value='3' selected={room === 3}>3</option>
                                                <option value='4' selected={room === 4}>4</option>
                                                <option value='5' selected={room === 5}>5</option>
                                                <option value='6' selected={room === 6}>6</option>
                                                <option value='7' selected={room === 7}>7</option>
                                                <option value='8' selected={room === 8}>8</option>
                                            </select>
                                        ) : room}
                                    </td> */}
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'roomType')}>
                                                <option value='1' selected={roomType === 1}>1</option>
                                                <option value='2' selected={roomType === 2}>2</option>
                                                <option value='3' selected={roomType === 3}>3</option>
                                            </select>
                                        ) : roomType}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                max='200'
                                                step={1}
                                                value={capacity} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'capacity')} 
                                            />
                                        ) : capacity}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'music')}>
                                                <option value='1' selected={music === 1}>1</option>
                                                <option value='2' selected={music === 2}>2</option>
                                                <option value='3' selected={music === 3}>3</option>
                                            </select>
                                        ) : music}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                max='100000'
                                                step={1}
                                                value={xpRequirement} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'xpRequirement')} 
                                            />
                                        ) : xpRequirement}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={vipPercentage} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'vipPercentage')} 
                                            />
                                        ) : `${vipPercentage}%`}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? 
                                            vipRequirement ? 'Yes' : 'No'
                                        : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'vipRequirement')}>
                                                <option value={true} selected={vipRequirement === true}>Yes</option>
                                                <option value={false} selected={vipRequirement === false}>No</option>
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={ticketPrice} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'ticketPrice')} 
                                            />
                                        ) : ticketPrice}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? findMap?.name : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'map')}>
                                                {maps.map(map => {

                                                    const isSelected = findMap?._id === map._id;

                                                    return <option key={map._id} value={map._id} selected={isSelected}>{map.name}</option>
                                                })}
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? car : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'car')}>
                                                {CarList.map(car => <option key={car} value={car}>{car}</option>)}
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? laps : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'laps')}>
                                                <option value={1} selected={laps === 1}>1</option>
                                                <option value={2} selected={laps === 2}>2</option>
                                                <option value={3} selected={laps === 3}>3</option>
                                                <option value={4} selected={laps === 4}>4</option>
                                                <option value={5} selected={laps === 5}>5</option>
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        <InputContainer>
                                            {editing && (
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    min='0' 
                                                    value={overallTime} 
                                                    onChange={(e) => changeInputHandler(e.target.value, _id, 'overallTime')} 
                                                    disabled={editing && type === 'open-race'}
                                                />
                                            )}
                                            {secondsFormat(overallTime)}
                                        </InputContainer>
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                value={totalAttempts} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'totalAttempts')} 
                                            />
                                        ) : totalAttempts}
                                    </td>
                                    {/* <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={maxNumberOfCollectibles} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'maxNumberOfCollectibles')} 
                                            />
                                        ) : maxNumberOfCollectibles}
                                    </td> */}
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={rewardMultiplier} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'rewardMultiplier')} 
                                            />
                                        ) : rewardMultiplier}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={color} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'color')} 
                                            />
                                        ) : color}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={teeShirtImage} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'teeShirtImage')} 
                                            />
                                        ) : teeShirtImage}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        <div style={{ display: 'flex' }}>
                                            <button 
                                                className="btn btn-warning" 
                                                style={{ marginRight: '10px' }} 
                                                onClick={() => editQuestHandler(_id) /*editing ? cancelHandler(_id) : editHandler(_id) */}
                                            >
                                                {editing ? 'Cancel' : 'Edit'}
                                            </button>
                                            <button 
                                                className="btn btn-danger" 
                                                onClick={() => deleteQuestHandler(_id) /*editing ? cancelHandler(_id) : editHandler(_id) */}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <tr>
                <td>
                    <div style={{ padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <button className="btn btn-warning" onClick={() => setShowModal(true)}>
                            Add New
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default InnerTable;