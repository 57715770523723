import {authFetch, fetchGet, fetchDelete} from "../helpers/fetchWrapper";

const baseRadioUrl = 'radio';
export const getRadioTracksRequest = async (type) => {
  const json = await fetchGet(type, `${baseRadioUrl}/all`);

  if (!json.success) {
      return;
  }

  return (json.data);
}

export const getRadioStationNamesRequest = async (type) => {
  const json = await fetchGet(type, `${baseRadioUrl}/groupNames`);

  if (!json.success) {
      return;
  }

  return (json.data);
}

export const uploadRadioTrackRequest = async (type, file) => {
  const formData = new FormData();
  formData.append('track', file);

  const response = await authFetch(type, `${baseRadioUrl}/upload`, 'POST', formData);
  if (!response.success) {
      alert(`Can't upload new track: ${response.message}`);
      return null;
  }

  return response.data;
}

export const deleteTrackRequest = async ({type, id}) => {
  const response = await fetchDelete(type, `${baseRadioUrl}/delete/${id}`);
  if (!response.success) {
      alert(`Can't delete video: ${response.message}`);
      return null;
  }

  alert('Deleted selected video');
  return response.data;
}