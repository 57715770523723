import React, { useState, useRef, useContext } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';

import { url } from '../Constants';

const Invites = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ invite, setInvite ] = useState('');

    const input = useRef(null);

    const createInvite = async e => {
        e.preventDefault();

        const create = await fetch(`${url(type)}/user/make-invite`, {
            method: 'POST'
        });

        const json = await create.json();

        const { token } = json.data;

        setInvite(token);
    }

    const copyInvite = text => {
        if(!text) setInvite('');
        input.current.select();
        document.execCommand('copy');
        // navigator.clipboard.writeText(`${type === GRID ? 'https://gridzone.io' : 'https://powercore.zone'}/invite/${text ? text : invite}`);
    }

    return (
        <Page title='Invites'>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div style={{ display: 'flex'}}>
                                <div style={{ width: '50%'}}>
                                    Create Invite
                                </div>
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <form onSubmit={createInvite}>
                                        <div className="form-group input-group">
                                            <input type="text" className="form-control" onChange={e => setInvite('')} value={invite} ref={input} />
                                            <span className="input-group-addon" style={{ cursor: 'pointer' }} onClick={() => copyInvite()}>Copy</span>
                                        </div>
                                        <button type="submit" className="btn btn-primary" onClick={createInvite}>Create Invite</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Invites;