import React, { useEffect, useState, useContext } from 'react';

import { AppContext } from '../../context/app-context';

import { url } from '../../Constants';

import { CarList, QuestTypes, secondsFormat } from './InnerTable'
import { useSettings } from '../../context/game-settings-context';

const NewQuestModal = ({ close, date, _id, maps, addNewCallback }) => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ car, setCar ] = useState([]); 
    const [ mapsSelect, setMapsSelect ] = useState([]);
    const [ questTypes, setQuestTypes ] = useState([]);

    useEffect(() => {
        const mapCar = [...CarList].map((car, index) => {
            return {
                car,
                selected: index === 0 ? true : false
            }
        });

        setCar(mapCar);

        const mapMaps = [...maps].map((m, index) => {
            return {
                ...m,
                selected: index === 0 ? true : false
            }
        });

        setMapsSelect(mapMaps);

        const mapQuestTypes = [...QuestTypes].map((type, index) => {
            return {
                type,
                selected: index === 0 ? true : false
            }
        });

        setQuestTypes(mapQuestTypes);

    }, [ maps ]);

    const changeSelectHandler = (value, type) => {
        if(type === 'car') {

            setCar(cars => {
                return [...cars].map(car => {
                    return {
                        ...car,
                        selected: car.car === value ? true : false
                    }
                })
            });
        }

        if(type === 'maps') {
            setMapsSelect(maps => {
                return [...maps].map(m => {
                    return {
                        ...m,
                        selected: m._id === value ? true : false
                    }
                })
            });
        }

        if(type === 'type') {
            setQuestTypes(types => {
                return [...types].map(questType => {
                    return {
                        ...questType,
                        selected: questType.type === value ? true : false
                    }
                })
            });
        }
    }

    const [ laps, setLaps ] = useState(1);
    const [ bestTime, setBestTime ] = useState(0);
    const [ overallTime, setOverallTime ] = useState(0);
    const [ credits, setCredits ] = useState(0);
    const [ totalAttempts, setTotalAttempts ] = useState(0);
    const [ costToStart, setCostToStart ] = useState(0);
    const [ requirement, setRequirement ] = useState(1);
    const [ maxNumberOfCollectibles, setMaxNumberOfCollectibles ] = useState(0);
    const [ rewardMultiplier, setRewardMultiplier ] = useState(0);

    const submitHandler = async e => {
        e.preventDefault();

        const token = localStorage.getItem('pcAdmin');

        const findCar = car.find(item => item.selected);
        const findMap = mapsSelect.find(item => item.selected);
        const findType = questTypes.find(item => item.selected);

        const bodyObj = {
            _id, 
            map: findMap._id, 
            car: findCar.car, 
            type: findType.type,
            laps, 
            bestTime, 
            overallTime, 
            credits, 
            totalAttempts, 
            costToStart, 
            requirement,
            maxNumberOfCollectibles,
            rewardMultiplier
        }

        const add = await fetch(`${url(type)}/daily-quests/add`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyObj)
        });

        const json = await add.json();

        if(json.success) {
            addNewCallback(json.data);
            close();
        } else {
            window.alert(json.message);
        }
    }

    const selectedType = questTypes.find(type => type.selected);

    useEffect(() => {
        if(selectedType?.type === 'collection' || selectedType?.type === 'open-race') {
            setRequirement(6);
        } else {
            setRequirement(1);
        }
    }, [ selectedType ]);

    const { settings, setSettings } = useSettings();

    const [ favoriteQuests, setFavoriteQuests ] = useState([]);
    const [ favoriteName, setFavoriteName ] = useState('');

    useEffect(() => {
        if(!settings) return;

        const mapQuests = [...settings.favoriteQuests].map((quest, index) => {
            return {
                ...quest,
                isSelected: index === 0 ? true : false
            }
        });

        setFavoriteQuests(mapQuests);
    }, [ settings ]);

    const selectFavoriteQuestHandler = (_id) => {
        setFavoriteQuests(quests => {
            return [...quests].map(quest => {
                return {
                    ...quest,
                    isSelected: quest._id === _id ? true : false
                }
            })
        })
    }

    const saveQuestAsFavorite = async () => {
        const token = localStorage.getItem('pcAdmin');

        const findCar = car.find(item => item.selected);
        const findMap = mapsSelect.find(item => item.selected);
        const findType = questTypes.find(item => item.selected);

        const add = await fetch(`${url(type)}/game-settings/favorites`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: settings._id,
                quest: {
                    name: favoriteName,
                    type: findType.type,
                    map: findMap._id,
                    car: findCar.car,
                    laps,
                    bestTime,
                    overallTime,
                    credits,
                    totalAttempts,
                    costToStart,
                    requirement,
                    rewardMultiplier,
                    maxNumberOfCollectibles
                }
            })
        });

        const json = await add.json();

        const { success, message, data } = json;

        if(success) {
            setSettings(data);
            setFavoriteName('');
        } else {
            window.alert(message);
        }
    }

    const importQuestHandler = () => {
        const findSelected = favoriteQuests.find(quest => quest.isSelected);

        const { type, map, car, laps, bestTime, overallTime, credits, totalAttempts, costToStart, requirement, maxNumberOfCollectibles, rewardMultiplier
        } = findSelected;

        setCar(cars => {
            return [...cars].map(c => {
                return {
                    ...c,
                    selected: c.car === car ? true : false
                }
            })
        });

        setMapsSelect(maps => {
            return [...maps].map(m => {
                return {
                    ...m,
                    selected: m._id === map ? true : false
                }
            })
        });

        setQuestTypes(types => {
            return [...types].map(t => {
                return {
                    ...t,
                    selected: t.type === type ? true : false
                }
            })
        });

        setLaps(laps);
        setBestTime(bestTime);
        setOverallTime(overallTime);
        setCredits(credits);
        setTotalAttempts(totalAttempts);
        setCostToStart(costToStart);
        setRequirement(requirement);
        setMaxNumberOfCollectibles(maxNumberOfCollectibles);
        setRewardMultiplier(rewardMultiplier);
    }


    return (
        <div style={{ 
            width: "100vw",
            height: '100vh',
            overflowY: 'scroll',
            background: 'rgba(0,0,0, 0.95)',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: '100'
        }}>
            <div onClick={close}>
                <div className="close"></div>
            </div>
            <div className="container">
                <div className="row" style={{ display: 'flex', justifyContent: 'center', margin: '20px 0', textAlign: 'center'}}>
                    <div className="col-md-9">
                        <h2 style={{ marginBottom: '30px'}}>Add new quest for date {date}</h2>
                        <form onSubmit={submitHandler}>
                            <Input>
                                <label>Quest Type</label>
                                <select className="form-control" onChange={(e) => changeSelectHandler(e.target.value, 'type')}>
                                    {questTypes.map(questType => <option key={questType.type} value={questType.type} selected={questType.selected}>{questType.type}</option>)}
                                </select>
                            </Input>
                            <Input>
                                <label>Select Map</label>
                                <select className="form-control" onChange={(e) => changeSelectHandler(e.target.value, 'maps')}>
                                    {mapsSelect.map(map => <option key={map._id} value={map._id} selected={map.selected}>{map.name}</option>)}
                                </select>
                            </Input>
                            <Input>
                                <label>Select Car</label>
                                <select className="form-control" onChange={(e) => changeSelectHandler(e.target.value, 'car')}>
                                    {car.map(item => <option key={item.car} value={item.car} selected={item.selected}>{item.car}</option>)}
                                </select>
                            </Input>
                            <Input>
                                <label>Total Laps</label>
                                <select className="form-control" onChange={(e) => setLaps(parseFloat(e.target.value))}>
                                    <option value={1} selected={laps === 1}>1</option>
                                    <option value={2} selected={laps === 2}>2</option>
                                    <option value={3} selected={laps === 3}>3</option>
                                    <option value={4} selected={laps === 4}>4</option>
                                    <option value={5} selected={laps === 5}>5</option>
                                </select>
                            </Input>
                            {selectedType?.type === 'beat-the-timer' && (
                                <Input>
                                    <label>Best Lap Time {secondsFormat(bestTime)} </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        min='0' 
                                        value={bestTime} 
                                        onChange={(e) => setBestTime(parseFloat(e.target.value))} 
                                    />
                                </Input>
                            )}
                            {selectedType?.type !== 'open-race' && (
                                <Input>
                                    <label>Overall Time {secondsFormat(overallTime)}</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        min='0' 
                                        value={overallTime} 
                                        onChange={(e) => setOverallTime(parseFloat(e.target.value))} 
                                    />
                                </Input>
                            )}
                            <Input>
                                <label>Credits Reward</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={credits} 
                                    onChange={(e) => setCredits(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Total Attempts</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    value={totalAttempts} 
                                    onChange={(e) => setTotalAttempts(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Chip Price</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    min='0' 
                                    step={0.00001}
                                    value={costToStart} 
                                    onChange={(e) => setCostToStart(parseFloat(e.target.value))} 
                                />
                            </Input>
                            <Input>
                                <label>Requirement</label>
                                <select 
                                    className="form-control" 
                                    onChange={(e) => setRequirement(parseFloat(e.target.value))}
                                    disabled={selectedType?.type === 'open-race' || selectedType?.type === 'collection'}
                                >
                                    <option value={1} selected={requirement === 1}>Level 1</option>
                                    <option value={2} selected={requirement === 2}>Level 2</option>
                                    <option value={3} selected={requirement === 3}>Level 3</option>
                                    <option value={4} selected={requirement === 4}>Level 4</option>
                                    <option value={5} selected={requirement === 5}>Level 5</option>
                                    <option value={6} selected={requirement === 6}>Neonpunk</option>
                                </select>
                            </Input>
                            {selectedType?.type === 'collection' && (
                                <Input>
                                    <label>Max Number Of Collectibles </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        min='0' 
                                        value={maxNumberOfCollectibles} 
                                        onChange={(e) => setMaxNumberOfCollectibles(parseFloat(e.target.value))} 
                                    />
                                </Input>
                            )}
                            {selectedType?.type === 'collection' && (
                                <Input>
                                    <label>Reward Multiplier </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        min='0' 
                                        step={0.00001}
                                        value={rewardMultiplier} 
                                        onChange={(e) => setRewardMultiplier(parseFloat(e.target.value))} 
                                    />
                                </Input>
                            )}
                            <button type="submit" class="btn btn-default">Submit Button</button>
                        </form>
                        
                    </div>
                    <div className="col-md-3">
                        <h2 style={{ marginTop: '30px'}}>Favorites</h2>
                        <select 
                            className="form-control" 
                            onChange={(e) => selectFavoriteQuestHandler(e.target.value)}
                            style={{ marginBottom: 10, width: '100%' }}
                        >
                            {favoriteQuests.map(quest => {

                                return (
                                    <option 
                                        value={quest._id} 
                                        selected={quest.isSelected}
                                    >
                                        {quest.name}
                                    </option>
                                )
                            })}
                        </select>
                        <button
                            type="button" 
                            class="btn btn-primary" 
                            style={{ marginBottom: 40 }}
                            onClick={importQuestHandler}
                        >
                            Import from favorites
                        </button>
                        <input 
                            type="text"
                            className="form-control"
                            placeholder='Favorite name'
                            value={favoriteName}
                            style={{ marginBottom: 10 }}
                            onChange={e => setFavoriteName(e.target.value)}
                        />
                        <button 
                            type="button" 
                            class="btn btn-warning"
                            onClick={saveQuestAsFavorite}
                        >
                            Save as favorite
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Input = ({ children }) => (
    <div className="row" style={{ display: 'flex', justifyContent: 'center'}}>
        <div className="col-md-4">
            <div class="form-group" style={{ marginBottom: '20px'}}>
                {children}
            </div>
        </div>
    </div>
)

export default NewQuestModal;