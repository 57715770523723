import {authFetch, fetchDelete, fetchPost} from "../helpers/fetchWrapper";

export const getRewardsRequest = async (type) => {
    const json = await authFetch(type, 'nft/rewards/all', 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const uploadRewardFile = async ({type, formData}) => {
    const response = await fetchPost(type, 'nft/rewards/upload', formData);
    if (!response.success) {
        alert(`Can't upload reward file: ${response.message}`);
        return response.message;
    }

    return response;
}

export const deleteRewardRequest = async ({type, id}) => {
    const response = await fetchDelete(type, `nft/rewards/${id}`);
    if (!response.success) {
        alert(`Can't delete reward: ${response.message}`);
        return null;
    }

    alert('Deleted selected reward');
    return response.data;
}
