import React from "react";
import DeleteBtn from "./DeleteBtn";
import FinishBtn from "./FinishBtn";
import ViewBtn from "./ViewBtn";

const PromotionList = ({promotions, onDelete, onFinish, onItemSelect}) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                List of promotions
            </div>
            <div className="panel-body">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Post ID</th>
                            <th>Channel</th>
                            <th>Active</th>
                            <th>Description</th>
                            <th>Max Eligible</th>
                            <th>Currently Eligible</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {promotions.map((item) => {
                            const { _id, channel, postId, description, active, maxEligible, eligibleUsers } = item;
                            return (
                                <tr key={_id}>
                                    <th>{postId}</th>
                                    <th>{channel}</th>
                                    <th>{active ? "Yes": "No"}</th>
                                    <th>{description}</th>
                                    <th>{maxEligible === -1 ? "Infinite": maxEligible}</th>
                                    <th>{eligibleUsers.length}</th>
                                    <td >
                                        <ViewBtn id={_id} onClickChange={onItemSelect} />
                                        <DeleteBtn id={_id} onClickChange={onDelete} />
                                        <FinishBtn id={_id} onClickChange={onFinish} />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export  default PromotionList;
