import LoadingBtn, {LoadingBtnType} from "./LoadingBtn";
import React from "react";

const RadioAddEdit = ({videoName, setVideoName, setFile, stations, station, setStationName, fetching, onAddRadioEntry}) => {
    return (
        <form className="version-form">
            <div className="row pb-4">
                
                <div className="col-md-12 mb-4">
                    <div className="form-group">
                        <label htmlFor="videoImage" className="col-md-1 input-label">Station:</label>
                        <div className="col-md-11">
                            <select className="form-control" id="promotionChannel"
                                    onChange={setStationName} value={station}>
                                {stations && stations.map((item) => {
                                    return (
                                        <option key={item} value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="videoName" className="col-md-2 input-label">Name:</label>
                        <div className="col-md-10">
                            <input type="text" id="videoName" placeholder="Name" className="form-control"
                                   onChange={e => setVideoName(e.target.value)} value={videoName} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="fileAudio" className="col-md-2 input-label">File:</label>
                        <div className="col-md-10">
                            <input type="file" id="fileAudio" placeholder="Image" className="form-control"
                                   onChange={e => setFile(e.target.files[0])} accept=".mp3" />
                        </div>
                    </div>
                </div>

                <LoadingBtn title={'Add station audio'} width={4} loading={fetching} type={LoadingBtnType.Primary} onClickChange={onAddRadioEntry} />

            </div>
        </form>
    )
}

export default RadioAddEdit;