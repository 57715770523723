import React, { useState } from 'react';

import InputContainer from './InputContainer';
import NewQuestModal from './NewQuestModal';

export const CarList = [
    'Outrun',
    'Juggernaut',        
    'Futuro',
    'Nitro',
    'Fantom',
    'Apex',
    'Baron',
    'Memento',
    'Avantguard',
    'Bandit',
    'Bavarian',
    'Yello',
    'Dash',
    'Commando',
    'Hippie',
    'Minion',
    'Nosferatu',
    'Subzero',
    'ZZTop',
    'Punisher',
    'Zond',
    'Akira',
    'Taurus',
    'Hornet',
    'Brute',
    'Samourai',
    'Wasp'
]

export const QuestTypes = ['beat-the-timer', 'collection', 'open-race'];

const tdStyle = {
    background: '#2c2e33' 
}

export const secondsFormat = (SECONDS) => {

    if(isNaN(SECONDS)) return;

    if(SECONDS < 3600) {
        return new Date(SECONDS * 1000).toISOString().substring(14, 19)
    } else {
        return new Date(SECONDS * 1000).toISOString().substring(11, 16)
    }
}

const InnerTable = ({ quests, maps, changeInputHandler, editQuestHandler, date, _id, addNewCallback, deleteQuest }) => {

    const [ showModal, setShowModal ] = useState(false);

    const deleteQuestHandler = (_id) => {
        const confirm = window.confirm('Are you sure you want to delete quest');

        if(confirm) {
            const filter = [...quests].filter(quest => quest._id !== _id);

            deleteQuest(filter);
        }
    }

    return (
        <>
            {showModal && <NewQuestModal close={() => setShowModal(false)} date={date} _id={_id} maps={maps} addNewCallback={addNewCallback} />}
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Race</th>
                            <th>Vehicle</th>
                            <th>Laps</th>
                            <th>Best Lap Time</th>
                            <th>Overall Time</th>
                            <th>Total Attempts</th>
                            <th>Chip Price</th>
                            <th>Requirement</th>
                            <th>Credits Reward</th>
                            <th>Max Collectibles</th>
                            <th>Reward Multiplier</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quests.map(quest => {

                            const { 
                                _id, 
                                type, 
                                bestTime, 
                                map, 
                                laps, 
                                overallTime, 
                                car, 
                                editing, 
                                credits, 
                                totalAttempts, 
                                costToStart, 
                                requirement, 
                                rewardMultiplier,
                                maxNumberOfCollectibles
                            } = quest;

                            const findMap = maps.find(m => m._id === map._id);

                            return (
                                <tr key={_id}>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'type')}>
                                                <option value='beat-the-timer' selected={type === 'beat-the-timer'}>beat-the-timer</option>
                                                <option value='collection' selected={type === 'collection'}>collection</option>
                                                <option value='open-race' selected={type === 'open-race'}>open-race</option>
                                            </select>
                                        ) : type}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? findMap?.name : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'map')}>
                                                {maps.map(map => {

                                                    const isSelected = findMap?._id === map._id;

                                                    return <option key={map._id} value={map._id} selected={isSelected}>{map.name}</option>
                                                })}
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? car : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'car')}>
                                                {CarList.map(car => <option key={car} value={car}>{car}</option>)}
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {!editing ? laps : (
                                            <select className="form-control" onChange={(e) => changeInputHandler(e.target.value, _id, 'laps')}>
                                                <option value={1} selected={laps === 1}>1</option>
                                                <option value={2} selected={laps === 2}>2</option>
                                                <option value={3} selected={laps === 3}>3</option>
                                                <option value={4} selected={laps === 4}>4</option>
                                                <option value={5} selected={laps === 5}>5</option>
                                            </select>
                                        )}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        <InputContainer>
                                            {editing && (
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    min='0' 
                                                    value={bestTime} 
                                                    onChange={(e) => changeInputHandler(e.target.value, _id, 'bestTime')} 
                                                    disabled={editing && type !== 'beat-the-timer'}
                                                />
                                            )}
                                            {secondsFormat(bestTime)}
                                        </InputContainer>
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        <InputContainer>
                                            {editing && (
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    min='0' 
                                                    value={overallTime} 
                                                    onChange={(e) => changeInputHandler(e.target.value, _id, 'overallTime')} 
                                                    disabled={editing && type === 'open-race'}
                                                />
                                            )}
                                            {secondsFormat(overallTime)}
                                        </InputContainer>
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                value={totalAttempts} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'totalAttempts')} 
                                            />
                                        ) : totalAttempts}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={costToStart} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'costToStart')} 
                                            />
                                        ) : costToStart}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <select 
                                                className="form-control" 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'requirement')}
                                                disabled={editing && (type === 'open-race' || type === 'collection')}  
                                                style={{
                                                    borderColor: 'red'
                                                }}  
                                            >
                                                <option value={1} selected={requirement === 1}>Level 1</option>
                                                <option value={2} selected={requirement === 2}>Level 2</option>
                                                <option value={3} selected={requirement === 3}>Level 3</option>
                                                <option value={4} selected={requirement === 4}>Level 4</option>
                                                <option value={5} selected={requirement === 5}>Level 5</option>
                                                <option value={6} selected={requirement === 6}>Neonpunk</option>
                                            </select>
                                        ) : requirement === 6 ? 'Neonpunk' : `Level ${requirement}`}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={credits} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'credits')} 
                                            />
                                        ) : credits}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={maxNumberOfCollectibles} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'maxNumberOfCollectibles')} 
                                                disabled={editing && type !== 'open-race'}
                                            />
                                        ) : maxNumberOfCollectibles}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        {editing ? (
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                min='0' 
                                                step={0.00001}
                                                value={rewardMultiplier} 
                                                onChange={(e) => changeInputHandler(e.target.value, _id, 'rewardMultiplier')} 
                                                disabled={editing && type !== 'open-race'}
                                            />
                                        ) : rewardMultiplier}
                                    </td>
                                    <td style={editing ? { minWidth: '80px' } : tdStyle}>
                                        <div style={{ display: 'flex' }}>
                                            <button 
                                                className="btn btn-warning" 
                                                style={{ marginRight: '10px' }} 
                                                onClick={() => editQuestHandler(_id) /*editing ? cancelHandler(_id) : editHandler(_id) */}
                                            >
                                                {editing ? 'Cancel' : 'Edit'}
                                            </button>
                                            <button 
                                                className="btn btn-danger" 
                                                onClick={() => deleteQuestHandler(_id) /*editing ? cancelHandler(_id) : editHandler(_id) */}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <tr>
                <td>
                    <div style={{ padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <button className="btn btn-warning" onClick={() => setShowModal(true)}>
                            Add New
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default InnerTable;