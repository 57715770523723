import LoadingBtn, {LoadingBtnType} from "./LoadingBtn";
import React from "react";

const VideoAddEdit = ({videoName, setVideoName, videoDescription, setVideoDescription,
                          setVideoFile, setVideoImage, groupNames, groupName, setVideoGroupName, fetching, onAddVideo}) => {
    return (
        <form className="version-form">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="videoName" className="col-md-2 input-label">Name:</label>
                        <div className="col-md-10">
                            <input type="text" id="videoName" placeholder="Name" className="form-control"
                                   onChange={e => setVideoName(e.target.value)} value={videoName} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="videoDescription" className="col-md-2 input-label">Description:</label>
                        <div className="col-md-10">
                            <input type="text" id="videoDescription" placeholder="Description" className="form-control"
                                   onChange={e => setVideoDescription(e.target.value)} value={videoDescription} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="videoFile" className="col-md-2 input-label">Video:</label>
                        <div className="col-md-10">

                            <input type="file" id="videoFile" placeholder="Video" className="form-control"
                                   onChange={e => setVideoFile(e.target.files[0])} accept="video/*" />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="videoImage" className="col-md-2 input-label">Image:</label>
                        <div className="col-md-10">

                            <input type="file" id="videoImage" placeholder="Image" className="form-control"
                                   onChange={e => setVideoImage(e.target.files[0])} accept="image/*" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="videoImage" className="col-md-2 input-label">Collection:</label>
                        <div className="col-md-10">
                            <select className="form-control" id="promotionChannel"
                                    onChange={setVideoGroupName} value={groupName}>
                                {groupNames.map((item) => {
                                    return (
                                        <option key={item} value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <LoadingBtn title={'Add video'} width={4} loading={fetching} type={LoadingBtnType.Primary} onClickChange={onAddVideo} />

            </div>
        </form>
    )
}

export default VideoAddEdit;
