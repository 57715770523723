import React, {useState, useContext, useEffect, useCallback} from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import PromotionAddEdit from "../components/PromotionAddEdit";
import PromotionList from "../components/PromotionList";
import {
    getPromotionChannelsRequest,
    getPromotionPostsRequest,
    getPromotionsRequest,
    updatePromotionRequest
} from "../effects/Promotions";
import {reloadPage} from "../helpers/page";
import {useHistory} from "react-router-dom";

const Promotions = () => {
    const appContext = useContext(AppContext);
    const history = useHistory();
    const { state, addPromotion, deletePromotion } = appContext;
    const { type, fetching } = state;

    const [ promotions, setPromotions ] = useState([]);
    const [ channels, setChannels ] = useState([]);
    const [ posts, setPosts ] = useState([]);
    const [ description, setDescription ] = useState('');
    const [ channel, setChannel ] = useState('');
    const [ postId, setPostId ] = useState('');
    const [ maxEligible, setMaxEligible ] = useState(-1);

    const getPromotions = useCallback(async () => {
        const response = await getPromotionsRequest(type);

        if (!response) {
            return;
        }

        setPromotions(response);
    }, [type])

    const getPosts = useCallback(async (channel) => {
        const posts = await getPromotionPostsRequest(type, channel);

        if (!posts) {
            return;
        }

        if (posts.length > 0) {
            setPostId(posts[0].id);
        }

        setPosts(posts);
    }, [type])

    const getChannels = useCallback(async () => {
        const channels = await getPromotionChannelsRequest(type);

        if (!channels) {
            return;
        }

        setChannels(channels);
        const firstChannel = channels[0];
        if (firstChannel) {
            setChannel(firstChannel)
            getPosts(firstChannel);
        }
    }, [type, getPosts])

    useEffect(() => {
        getPromotions();
        getChannels();
    }, [type, getChannels, getPromotions]);

    const onChannelChange = async  (e) => {
        const channelId = e.target.value;
        setChannel(channelId);
        await getPosts(channelId);
    }

    const onAdd = async (e) => {
        const result = await addPromotion(description, channel, postId, maxEligible);
        if (result != null) {
            reloadPage();
        }
    }

    const onDelete = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete promotion?');
        if (!confirmed) {
            return;
        }

        const result = await deletePromotion(id);
        if (result != null) {
            reloadPage();
        }
    }

    const onFinish = async (id) => {
        const finished = await updatePromotionRequest({type, id, active: false});
        if (finished) {
            reloadPage();
        }
    }

    const onItemSelect = (id) => {
        history.push(`/promotions/${id}`);
    }

    return (
        <Page title='Promotions'>
            <PromotionAddEdit fetching={fetching} onAdd={onAdd}
                              setPostId={setPostId} postId={postId} posts={posts}
                              description={description} setDescription={setDescription}
                              channel={channel} channels={channels} onChannelChange={onChannelChange}
                              maxEligible={maxEligible} setMaxEligible={setMaxEligible}/>
            <PromotionList promotions={promotions} onDelete={onDelete} onFinish={onFinish} onItemSelect={onItemSelect}/>
        </Page>
    )
}

export default Promotions;
