import React, {useContext, useEffect} from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import {Link} from "react-router-dom";

import { url } from '../Constants';
import { useState } from 'react';

const UsersCreateAdmin = () => {
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ password, setPassword ] = useState('');
    const [ users, setUsers ] = useState([]);

    useEffect(() => {
        const getUsers = () => {
            const fetchUsers = async () => {
                const get = await fetch(`${url(type)}/user/all`);
    
                const json = await get.json();
                const { data } = json;
    
                const map = data.map((user, index) => {
                    return {
                        ...user,
                        selected: index === 0 ? true : false
                    }
                })

                setUsers(map);
            }
    
            fetchUsers();
        }

        getUsers();

    }, [ type ]);

    const selectUserHandler = (_id) => {
        setUsers(users => {
            return [...users].map(user => {
                return {
                    ...user,
                    selected: user._id === _id ? true : false
                }
            });
        });
    }

    // const onCreateUser = async () => {
    //     const data = await fetchPostJson(type, 'auth/register', {
    //         email: loginUsernameRef.current.value,
    //         password: loginPasswordRef.current.value,
    //         confirmPassword: loginPasswordRef.current.value
    //     });
    //     if (!data.success) {
    //         alert(data.message);
    //     }
    // }

    const submitHandler = async e => {

        const token = localStorage.getItem('pcAdmin');

        const selectedUser = users.find(user => user.selected);

        if(!selectedUser) return window.alert('Please select user')

        const submit = await fetch(`${url(type)}/auth/admin`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: selectedUser._id,
                password
            })
        });
    
        const json = await submit.json();
        
        if(!json.success) {
            window.alert(json.message);
        } else {
            window.alert('User is now admin')
            setPassword('');
            setUsers(users => {
                return [...users].map(user => {
                    return {
                        ...user,
                        userType: selectedUser._id === user._id ? 'admin' : user.userType
                    }
                })
            });
        }
    }

    return (
        <Page title='Add new admin'>
            <div className="version-form">
                <Link to="/users">Back</Link>

                <form>
                    <div className="input-wrapper">
                        {/* <div className="input-desc">
                            Select user
                        </div> */}
                        <select className="form-control" onChange={(e) => selectUserHandler(e.target.value)}>
                            {users.map(user => <option key={user._id} value={user._id} selected={user.selected}>
                                {user.userName} {user.userType === 'admin' ? '-ADMIN-' : ''}
                            </option>)}
                        </select>
                    </div>
                    <div className="input-wrapper">
                        <div className="input-desc">
                            Add Password
                        </div>
                        <input 
                            type="password" 
                            className="form-control" 
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                    </div>
                </form>
                <div className={'row'} style={{alignItems: 'center', justifyContent: 'center', display: 'flex', paddingTop: '25px'}}>
                    <button type="button" className="btn btn-primary col-md-2" onClick={submitHandler}>Add admin</button>
                </div>

            </div>

        </Page>
    )
}

export default UsersCreateAdmin;
