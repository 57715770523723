import LoadingBtn, {LoadingBtnType} from "./LoadingBtn";
import React from "react";

const PromotionAddEdit = ({description, setDescription, channel, channels, onChannelChange,
                              postId, setPostId, posts, fetching, onAdd, maxEligible, setMaxEligible}) => {
    return (
        <form className="promotion-form">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="promDesc" className="col-md-2 input-label">Description:</label>
                        <div className="col-md-10">
                            <input type="text" autoComplete="off" id="promDesc" placeholder="Description" className="form-control"
                                   onChange={e => setDescription(e.target.value)} value={description} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="promotionChannel" className="col-md-2 input-label">Channel:</label>
                        <div className="col-md-10">
                            <select className="form-control" id="promotionChannel"
                                    onChange={onChannelChange} value={channel}>
                                {channels.map((item) => {
                                    return (
                                        <option key={item} value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="maxEligible" className="col-md-2 input-label">Max eligible users:</label>
                        <div className="col-md-10">
                            <input type="number" autoComplete="off" id="maxEligible" placeholder="Max number of users that can get promotion" className="form-control"
                                   onChange={e => setMaxEligible(e.target.value)} value={maxEligible} min={-1} />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="promotionPost" className="col-md-1 input-label">Post:</label>
                        <div className="col-md-11">
                            <select className="form-control" id="promotionPost"
                                    onChange={e => setPostId(e.target.value)} value={postId}>
                                {posts.map((item) => {
                                    const {id, content} = item;
                                    return (
                                        <option key={id} value={id}>{content}</option>
                                    )
                                })}
                            </select>

                        </div>
                    </div>
                </div>

                <LoadingBtn title={'Add promotion'} width={4} loading={fetching}
                            type={LoadingBtnType.Primary} onClickChange={onAdd} />
            </div>
        </form>
    )
}

export default PromotionAddEdit;
