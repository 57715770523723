import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/app-context';

import { url } from '../Constants';

import Page from '../components/Page';
import InnerTable from '../components/DailyQuests/InnerTable';

const DailyQuests = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ serverQuests, setServerQuests ] = useState([]); // original array from server, if canceled will be updated from here
    const [ quests, setQuests ] = useState([]);
    const [ serverTime, setServerTime ] = useState(undefined);
    const [ maps, setMaps ] = useState([]);

    useEffect(() => {
        const getQuests = async () => {

            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/daily-quests`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            const json = await get.json();

            const { data } = json;

            if(data?.quests && data?.serverTime) {

                const mapDays = [...data.quests].map(day => {

                    const mapQuests = day.quests.map(quest => {
                        return {
                            ...quest,
                            editing: false
                        }
                    })

                    return {
                        ...day,
                        quests: mapQuests,
                        editing: false
                    }
                });

                setQuests(mapDays);
                setServerQuests(mapDays);

                setServerTime(new Date(data.serverTime));
            }
        }

        getQuests();
    }, [ type ]);

    useEffect(() => {
        const getMaps = async () => {
            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/maps`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            const json = await get.json();

            if(json.success && json.data) {
                setMaps(json.data);
            }
        }

        getMaps();
    }, [ type ]);

    const makeDate = (d) => {
        const date = new Date(d);

        const day = date.getDay();
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const month = date.getMonth();
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        return `${days[day]}, ${date.getDate()} ${months[month]}`;
    }

    const editDayHandler = (_id) => {
        setQuests(quests => {
            return [...quests].map(quest => {
                return {
                    ...quest,
                    editing: quest._id === _id ? true : quest.editing
                }
            });
        })
    }

    const editQuestHandler = (_id) => {
        setQuests(quests => {
            return [...quests].map(quest => {

                const mapQuests = [...quest.quests].map(q => {
                    return {
                        ...q,
                        editing: q._id === _id ? !q.editing : q.editing
                    }
                });

                return {
                    ...quest,
                    quests: mapQuests
                }
            });
        })
    }

    const cancelHandler = (_id) => { // cancel editing and update from original array
        const findServerQuest = [...serverQuests].find(quest => quest._id === _id);

        setQuests(quests => {
            return [...quests].map(quest => {
                return quest._id === _id ? findServerQuest : quest
            })
        });
    }

    const changeInputHandler = (value, _id, type) => {

        setQuests(days => {
            return [...days].map(day => {

                const mapQuests = [...day.quests].map(quest => {

                    const currentInput = quest._id === _id;

                    const getNumberValue = () => {
    
                        if(value === '') {
                            return 0;
                        } else {
                            const parseValue = parseFloat(value);
                            return !isNaN(parseValue) ? parseValue : 0;
                        }
                    }
    
                    if((currentInput && type === 'map')) {
                        console.log(value, quest.map)
                    }

                    const getMapObject = () => {
                        const findMap = maps.find(map => map._id === value);
                        return findMap
                    }


                    return {
                        ...quest,
                        type: (currentInput && type === 'type') ? value : quest.type,
                        laps: (currentInput && type === 'laps') ? getNumberValue() : quest.laps,
                        map: (currentInput && type === 'map') ? getMapObject() : quest.map,
                        credits: (currentInput && type === 'credits') ? getNumberValue() : quest.credits,
                        car: (currentInput && type === 'car') ? value : quest.car,
                        bestTime: (currentInput && type === 'bestTime') ? getNumberValue() : quest.bestTime,
                        overallTime: (currentInput && type === 'overallTime') ? getNumberValue() : quest.overallTime,
                        totalAttempts: (currentInput && type === 'totalAttempts') ? getNumberValue() : quest.totalAttempts,
                        costToStart: (currentInput && type === 'costToStart') ? getNumberValue() : quest.costToStart,
                        requirement: (currentInput && type === 'requirement') ? getNumberValue() 
                        : (currentInput && (quest.type === 'collection' || quest.type === 'open-race')) ? 6 
                        : quest.requirement,
                        rewardMultiplier: (currentInput && type === 'rewardMultiplier') ? getNumberValue() : quest.rewardMultiplier,
                        maxNumberOfCollectibles: (currentInput && type === 'maxNumberOfCollectibles') ? getNumberValue() : quest.maxNumberOfCollectibles
                    };

                });

                const isCurrentDay = day._id === _id;

                return {
                    ...day,
                    quests: mapQuests,
                    availableQuestsRewards: (isCurrentDay && type === 'availableQuestsRewards') ? value : day.availableQuestsRewards
                }
            })
        });
    }

    useEffect(() => {
        if(!serverTime) return;

        const date = new Date(serverTime);

        const timer = setTimeout(() => {
            const increaseBySecond = date.getTime() + 1000;

            setServerTime(new Date(increaseBySecond));
        }, 1000);

        return () => clearTimeout(timer);

    }, [ serverTime ]);

    const updateMemoryQuestCallback = (newQuest) => { // when adding new quest we will update current quests with new one from server

        // we get latest quest and update its properties
        const latestQuest = {
            ...newQuest.quests[newQuest.quests.length - 1],
            editing: false
        };

        
        setQuests(quests => {
            return [...quests].map(quest => {

                const updatedQuests = newQuest._id === quest._id ? [...quest.quests, latestQuest] : [...quest.quests];

                return {
                    ...quest,
                    quests: updatedQuests
                }
            })
        });
        setServerQuests(quests => {
            return [...quests].map(quest => {

                const updatedQuests = newQuest._id === quest._id ? [...quest.quests, latestQuest] : [...quest.quests];

                return {
                    ...quest,
                    quests: updatedQuests
                }
            })
        });
    }
    
    const [ copiedQuests, setCopiedQuests ] = useState([]);

    const copyHandler = (quests) => {
        const map = [...quests].map(quest => {
            return {
                ...quest,
                copied: true
            }
        });
        setCopiedQuests(map);
    }

    const pasteHandler = (_id) => {
        setQuests(quests => {
            return [...quests].map(quest => {
                return {
                    ...quest,
                    quests: quest._id === _id ? copiedQuests : quest.quests
                }
            })
        })
    }

    const updateQuestHandler = async (quest) => {

        const token = localStorage.getItem('pcAdmin');

        const isCopied = quest.quests.find(quest => quest.copied);

        if(!isCopied) { // adding one new quest
            const mapQuests = [...quest.quests].map(quest => {
                return {
                    ...quest,
                    map: quest.map._id
                }
            });

            console.warn('Add availableQuestsRewards field')
    
            const update = await fetch(`${url(type)}/daily-quests`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _id: quest._id,
                    quests: mapQuests,
                    availableQuestsRewards: quest.availableQuestsRewards
                })
            });
    
            const json = await update.json();
    
            if(json.data) {
    
                console.log(json.data)
    
                setQuests(quests => {
                    return [...quests].map(quest => {
                        return quest._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : quest
                    })
                });
    
                setServerQuests(quests => {
                    return [...quests].map(quest => {
                        return quest._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : quest
                    })
                });
            }
        } else { // adding multiple quests
            const mapQuests = [...quest.quests].map(quest => {
                return {
                    ...quest,
                    _id: undefined,
                    map: quest.map._id
                }
            }); 

            const paste = await fetch(`${url(type)}/daily-quests/paste`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _id: quest._id,
                    quests: mapQuests
                })
            });
    
            const json = await paste.json();

            if(json.success) {
                setQuests(quests => {
                    return [...quests].map(quest => {
                        return quest._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : quest
                    })
                });
    
                setServerQuests(quests => {
                    return [...quests].map(quest => {
                        return quest._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : quest
                    })
                });
            }

            // console.log(json)
        }
        
    }

    return (
        <Page title={
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h2>Daily Quests</h2>
                {serverTime && <h2>Server Time: {serverTime.toISOString().substring(11, 19)} </h2>}
            </div>
        }
        >
            <div className="panel panel-default">
                <div className="panel-heading" style={{display: 'flex', flexDirection: 'row'}}>
                    
                </div>
                <div className="panel-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quests && serverTime && maps && quests.map((quest, index) => {
                                    const { _id, day, quests, editing } = quest;

                                    const notActive = () => {
                                        return new Date(day).getTime() <= new Date(serverTime).getTime() ? 'danger' : '';
                                    }

                                    const date = makeDate(day);

                                    const deleteQuest = async (quests) => {

                                        const thisQuest = {
                                            ...quest, // current quest object
                                            quests // filtered quests from frontend
                                        }

                                        await updateQuestHandler(thisQuest);
                                    }

                                    return (
                                        <>
                                            <tr key={_id} className={notActive()} style={{ cursor: notActive() ? 'not-allowed' : 'default' }}>
                                                <td style={{ padding: '20px 10px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                        <div>
                                                        <p>{date}</p> 
                                                        <p>
                                                            Available Rewards: &nbsp;
                                                            {!editing ? quest.availableQuestsRewards : (
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    min='0' 
                                                                    value={quest.availableQuestsRewards} 
                                                                    onChange={(e) => changeInputHandler(e.target.value, _id, 'availableQuestsRewards')}
                                                                />
                                                            )}
                                                        </p>
                                                        </div>
                                                        <div>
                                                            {!notActive() && (
                                                                <button 
                                                                    className="btn btn-danger" 
                                                                    style={{ marginRight: '10px' }} 
                                                                    onClick={() => editing ? cancelHandler(_id) : editDayHandler(_id)}
                                                                >
                                                                    {editing ? 'Close' : 'Open'}
                                                                </button>
                                                            )}
                                                            {editing && (
                                                                <button 
                                                                    className="btn btn-primary" 
                                                                    style={{ marginRight: '10px' }} 
                                                                    onClick={() => quests.length > 0 && copyHandler(quests)}
                                                                    disabled={quests.length === 0}
                                                                >
                                                                    Copy
                                                                </button>
                                                            )}
                                                            {editing && (
                                                                <button 
                                                                    className="btn btn-info" 
                                                                    style={{ marginRight: '10px' }} 
                                                                    onClick={() => quests.length === 0 && pasteHandler(_id)}
                                                                    disabled={quests.length > 0}
                                                                >
                                                                    Paste
                                                                </button>
                                                            )}
                                                            {editing && (
                                                                <button className="btn btn-success" onClick={() => updateQuestHandler(quest)}>
                                                                    Update
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {editing && (
                                                <InnerTable 
                                                    quests={quests} 
                                                    maps={maps} 
                                                    changeInputHandler={changeInputHandler} 
                                                    editQuestHandler={editQuestHandler} 
                                                    date={date}
                                                    _id={_id}
                                                    addNewCallback={updateMemoryQuestCallback}
                                                    deleteQuest={deleteQuest}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default DailyQuests;