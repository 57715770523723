import {authFetch, fetchDelete} from "../helpers/fetchWrapper";

export const getPromotionsRequest = async (type) => {
    const json = await authFetch(type, 'promotion/all', 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const getPromotionByIdRequest = async (type, id) => {
    const json = await authFetch(type, `promotion/${id}`, 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const getPromotionChannelsRequest = async (type) => {
    const json = await authFetch(type, 'promotion/channels', 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const getPromotionPostsRequest = async (type, channel) => {
    const json = await authFetch(type, `promotion/posts/${channel}`, 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const addPromotionRequest = async ({type, channel, description, postId, maxEligible}) => {
    const payload = JSON.stringify({
        channel, description, postId, maxEligible
    });
    const response = await authFetch(type, 'promotion/add', 'POST', payload);
    if (!response.success) {
        alert(`Can't add new promotion: ${response.message}`);
        return null;
    }

    alert('Added new promotion');
    return response.data;
}

export const updatePromotionRequest = async ({type, id, active}) => {
    const payload = JSON.stringify({
        active, id
    });
    const response = await authFetch(type, 'promotion/edit', 'PUT', payload);
    if (!response.success) {
        alert(`Can't update promotion: ${response.message}`);
        return null;
    }

    alert('Updated promotion status');
    return response.data;
}

export const deletePromotionRequest = async ({type, id}) => {
    const response = await fetchDelete(type, `promotion/${id}`);
    if (!response.success) {
        alert(`Can't delete video: ${response.message}`);
        return null;
    }

    alert('Deleted selected video');
    return response.data;
}
