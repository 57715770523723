import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import {getVideoGroupNamesRequest, getVideosRequest} from '../effects/Videos'
import VideoAddEdit from "../components/VideoAddEdit";
import VideoList from "../components/VideoList";
import LoadingBtn, {LoadingBtnType} from "../components/LoadingBtn";

const VideoStatus = Object.freeze({ VideoUpload:"VideoUpload", ImageUpload:"ImageUpload",
        VideoStreamProcessing: "VideoStreamProcessing", VideoUploadFinished: "VideoUploadFinished"});

const Videos = () => {
    const appContext = useContext(AppContext);
    const { state, addVideo, deleteVideo, updateVideos } = appContext;
    const { type, fetching } = state;

    const [ videos, setVideos ] = useState([]);
    const [ groupNames, setGroupNames ] = useState([]);
    const [ videoDescription, setVideoDescription ] = useState('');
    const [ videoName, setVideoName ] = useState('');
    const [ videoFile, setVideoFile ] = useState(null);
    const [ videoImage, setVideoImage ] = useState(null);
    const [ videoCollection, setVideoCollection ] = useState('');
    const [ uploadStatus, setUploadStatus] = useState(null);
    const [ isDataChanged, setIsDataChanged] = useState(false);

    const getVideos = async () => {
        const videos = await getVideosRequest(type);
        setVideos(videos);
    }

    const getGroupNames = async  () => {
        const videos = await getVideoGroupNamesRequest(type);
        setGroupNames(videos);
    }

    useEffect(() => {
        getVideos();
        getGroupNames();
    }, [type]);

    const renderUploadStatus = (param) => {
        switch(param) {
            case VideoStatus.VideoUpload:
                return 'Video is uploading...';
            case VideoStatus.ImageUpload:
                return 'Image is uploading...';
            case VideoStatus.VideoStreamProcessing:
                return 'Video file is processed into streaming file format';
            case VideoStatus.VideoUploadFinished:
                return 'Finished uploading video';
            default:
                return null;
        }
    }

    const onAddVideo = async () => {
        const result = await addVideo(videoDescription, videoName, videoFile, videoImage, {
            videoUploadStart: () => {
                setUploadStatus(VideoStatus.VideoUpload);
            },
            videoImageUploadStart: () => {
                setUploadStatus(VideoStatus.ImageUpload);
            },
            videoStreamingProcessingStart: () => {
                setUploadStatus(VideoStatus.VideoStreamProcessing);
            }
        });

        // If there was no error reload page
        if (result != null) {
            window.location.reload();
        }
    }

    const onDeleteVideo = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete video?');
        if (!confirmed) {
            return;
        }

        const result = await deleteVideo(id);
        if (result != null) {
            window.location.reload();
        }
    }

    const onUpdateVideos = async () => {
        const result = await updateVideos(videos);
        if (result != null) {
            window.location.reload();
        }
    }

    const onVideoDragEnd = (result) => {
        if (!result.destination) return;

        setIsDataChanged(true);
        const items = Array.from(videos);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setVideos(items);
    }

    return (
        <Page title='Videos'>
            <VideoAddEdit fetching={fetching} onAddVideo={onAddVideo} setVideoName={setVideoName} videoName={videoName}
                videoDescription={videoDescription} setVideoDescription={setVideoDescription}
                          setVideoFile={setVideoFile} setVideoImage={setVideoImage} groupName={videoCollection} setVideoGroupName={setVideoCollection}
                          groupNames={groupNames}/>
            <div className='video-upload-status'>
                <h1 className='video-upload-status-text'>{renderUploadStatus(uploadStatus)}</h1>
            </div>

            <VideoList videos={videos} onDeleteVideo={onDeleteVideo} onDragVideoEnd={onVideoDragEnd} />
            { isDataChanged ? <LoadingBtn type={LoadingBtnType.Warning} title={'Update'} width={4} loading={fetching} onClickChange={onUpdateVideos} />: null}
        </Page>
    )
}

export default Videos;
