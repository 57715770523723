import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import AppContextProvider from './context/app-context';
import { SettingsProvider } from './context/game-settings-context';

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
        <SettingsProvider>
            <App />
        </SettingsProvider>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);