import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../context/app-context";
import {
    getPromotionByIdRequest
} from "../effects/Promotions";
import {Link, useParams} from 'react-router-dom';

import Page from "../components/Page";
import PromotionDetailsEligibleUsers from "../components/PromotionDetailsEligibleUsers";
// , match.params.id

const PromotionDetails = () => {
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;
    let { id } = useParams();

    const [ promotion, setPromotion ] = useState([]);

    const getPromotionById = useCallback(async (id) => {
        const localPromotion = await getPromotionByIdRequest(type, id);

        if (!localPromotion) {
            return;
        }
        console.info('Prom id', localPromotion);
        setPromotion(localPromotion);
    }, [type])

    useEffect(() => {
        getPromotionById(id);
    }, [type, id, getPromotionById]);

    return (
        <Page title='PromotionDetails'>
            {promotion ? (
                <div className={'promotion_details'}>
                    <Link to="/promotions">Back</Link>
                    <div>
                        <label className={''}>Post Id: </label>
                        <span>{promotion.postId}</span>
                    </div>
                    <div>
                        <label>Description: </label>
                        <span>{promotion.description}</span>
                    </div>
                    <div>
                        <label>Channel: </label>
                        <span>{promotion.channel}</span>
                    </div>
                    <PromotionDetailsEligibleUsers users={promotion.eligibleUsers} />
                </div>

            ) : null}
        </Page>
    )
}

export  default PromotionDetails;
