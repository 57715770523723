import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/app-context';

import { url } from '../Constants';

import Page from '../components/Page';

const Subscriptions = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ users, setUsers ] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const users = await fetch(`${url(type)}/user/get-subscribers`);
            const json = await users.json();
    
            setUsers(json.data);
        }

        getUsers();
    }, [type]);

    const sendInvite = async email => {
        const create = await fetch(`${url(type)}/user/make-invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        });

        await create.json();

        alert('invited');
    }

    return (
        <Page title='Subscriptions'>
            <div className="panel panel-default">
                <div className="panel-heading">
                    List of subscribers
                </div>
                <div className="panel-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Invite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => {
                                    const { _id, email } = user;
                                    return (
                                        <tr key={_id}>
                                            <th>{email}</th>
                                            <th>
                                                <button className="btn btn-primary" onClick={() => sendInvite(email)}>
                                                    Invite
                                                </button>
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Subscriptions;