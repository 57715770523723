import React from 'react';

const DeleteBtn = ({ id, onClickChange }) => {
    const handleClick = () => {
        onClickChange(id);
    }

    return (
        <i onClick={handleClick} className='fa fa-trash-o fa-2x delete' />
    )
}

export default DeleteBtn;
