export const POWERCORE = 'Powercore';
export const GRID = 'Grid';

// export const GRID_URL = 'http://localhost:4040';
// export const POWERCORE_URL = 'http://localhost:4040'; 

export const GRID_URL = 'https://gridzone.io/api';
export const POWERCORE_URL = 'https://powercore.zone/api';

export const url = type => {
    return type === GRID ? GRID_URL : POWERCORE_URL;
}
