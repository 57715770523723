import React from 'react';
import BaseFaBtn from "./BaseFaBtn";

const FinishBtn = ({ id, onClickChange }) => {
    return (
        <BaseFaBtn id={id} onClickChange={onClickChange} faClassName={'fa-check-circle-o'} />
    )
}

export default FinishBtn;
