import React from 'react';
import BaseFaBtn from "./BaseFaBtn";

const ViewBtn = ({ id, onClickChange }) => {
    return (
        <BaseFaBtn id={id} onClickChange={onClickChange} faClassName={'fa-eye mr-5'} />
    )
}

export default ViewBtn;
