import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/app-context';

import { url } from '../Constants';

const AddNFT = props => {
    const appContext = useContext(AppContext);
    const { type } = appContext.state;

    const { state } = props;

    const [ editing, setEditing ] = useState(false);
    const [ serverMessage, setServerMessage ] = useState('');

    const [ name, setName ] = useState('');
    const [ order, setOrder ] = useState('1');
    const [ available, setAvailable ] = useState('1');
    const [ total, setTotal ] = useState('1');
    const [ creator, setCreator ] = useState('Martin Tan');
    const [ contractAddress, setContractAddress ] = useState('0x4r32wrfdssf7sd6f98sd');
    const [ owner, setOwner ] = useState('Martin Tan');
    const [ ownerAddress, setOwnerAddress ] = useState('0x4r32wrfdssf7sd6f98sd');
    const [ collection, setCollection ] = useState('Totems');
    const [ parentCollection, setParentCollection ] = useState('Cards');
    const [ tokenID, setTokenID ] = useState('121-001');
    const [ network, setNetwork ] = useState('Ethereum');
    const [ NYMPowerBonus, setNYMPowerBonus ] = useState('200 NP');
    const [ price, setPrice ] = useState('');
    const [ frontImage, setFrontImage ] = useState(null);
    const [ frontImageSmall, setFrontImageSmall ] = useState(null);
    const [ backImage, setBackImage ] = useState(null);
    const [ backImageSmall, setBackImageSmall ] = useState(null);
    const [modelId, setModelId] = useState('');
    const [ imagesEdited, setImagesEdited ] = useState({
        front: false,
        frontSmall: false,
        rear: false,
        rearSmall: false
    });

    useEffect(() => {
        if(collection === 'Totems') setParentCollection('Cards');
        if(collection === 'Martin-Tan' || collection === 'GridZone') setParentCollection('Nym Fashion');
        if(collection === 'Legendary'
            || collection === 'Classic'
            || collection === 'Cars'
            || collection === 'Bikes'
        ) setParentCollection('Rides');
    }, [collection]);

    useEffect(() => {
        if(state) setEditing(state.edit);
    }, [state]);

    const setState = (name = '', order = '1', available = '1', total = '1', creator = 'Martin Tan',
                      contractAddress = '0x4r32wrfdssf7sd6f98sd', owner = 'Martin Tan', ownerAddress = '',
                      collection = 'Totems', tokenID = '121-001', network = 'Ethereum',
                      NYMPowerBonus = '200 NP', price = '', frontImage = null, frontImageSmall = null,
                      backImage = null, backImageSmall = null, modelId = '') => {
        setName(name);
        setOrder(order);
        setAvailable(available);
        setTotal(total);
        setCreator(creator);
        setContractAddress(contractAddress);
        setOwner(owner);
        setOwnerAddress(ownerAddress);
        setCollection(collection);
        setTokenID(tokenID);
        setNetwork(network);
        setNYMPowerBonus(NYMPowerBonus);
        setPrice(price);
        setFrontImage(frontImage);
        setFrontImageSmall(frontImageSmall);
        setBackImage(backImage);
        setBackImageSmall(backImageSmall);
        setModelId(modelId);
    }

    useEffect(() => {
        if(editing) {
            const { nft } = state;
            if(nft) {
                const { name, order, available, totalToMint, creator, contractAddress, owner, ownerAddress, collections, tokenID, network, nymPowerBonus, price, modelId } = nft;
                setState(name, order, available, totalToMint, creator, contractAddress, owner, ownerAddress, collections.main, tokenID, network, nymPowerBonus, price, modelId);
            } else {
                setState();
            }
        }
    }, [editing, state]);

    const editImageHandler = (type, file) => {

        switch(type) {
            case 'front':
                setFrontImage(file);
                break;
            case 'front-small':
                setFrontImageSmall(file);
                break;
            case 'rear':
                setBackImage(file);
                break;
            case 'rear-small':
                setBackImageSmall(file);
                break;
            default:
                console.log('wrong type')
        }

        if(editing) {
            setImagesEdited({
                front: type === 'front' ? true : imagesEdited.front,
                frontSmall: type === 'front-small' ? true : imagesEdited.frontSmall,
                rear: type === 'rear' ? true : imagesEdited.rear,
                rearSmall: type === 'rear-small' ? true : imagesEdited.rearSmall
            });
        }
    }

    const submitFormHandler = async e => {
        e.preventDefault();
        const token = localStorage.getItem('pcAdmin');

        const formData = new FormData();

        if(editing) {
            formData.append('_id', state.nft._id);
            formData.append('imagesEdited', JSON.stringify(imagesEdited));
        }
        formData.append('name', name);
        formData.append('order', order);
        formData.append('available', available);
        formData.append('totalToMint', total);
        formData.append('creator', creator);
        formData.append('contractAddress', contractAddress);
        formData.append('owner', owner);
        formData.append('ownerAddress', ownerAddress);
        formData.append('collections', JSON.stringify({
            main: collection,
            parentCollection: parentCollection
        }));
        formData.append('tokenID', tokenID);
        formData.append('network', network);
        formData.append('nymPowerBonus', NYMPowerBonus);
        formData.append('price', price);
        formData.append('modelId', modelId)
        formData.append('nft-images', frontImage);
        formData.append('nft-images', frontImageSmall);
        formData.append('nft-images', backImage);
        formData.append('nft-images', backImageSmall);

        const fetchUrl = editing ? `${url(type)}/nft/edit-nft` : `${url(type)}/nft/add-nft`;

        const send = await fetch(fetchUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: formData
        });

        const json = await send.json();
        const { success, message } = json;
        if(!success) return setServerMessage(message);

        setServerMessage(editing ? 'Edit success' : 'Add NFT success');
        setState();
    }

    useEffect(() => {
        if(serverMessage) {
            setTimeout(() => {
                setServerMessage('');
            }, 1600);
        }
    }, [serverMessage]);

    const previewImgStyle = { width: '100%', height: 'auto', objectFit: 'contain', marginTop: '10px'};

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                Add NFT
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-md-2" />
                    <div className="col-md-8">
                        <form onSubmit={submitFormHandler}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input className="form-control" type="text" value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Price</label>
                                        <input className="form-control" type="number" min="0" value={price} onChange={e => setPrice(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Available</label>
                                        <input className="form-control" type="number" min="0" value={available} onChange={e => setAvailable(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Total</label>
                                        <input className="form-control" type="number" min="0" value={total} onChange={e => setTotal(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input className="form-control" type="number" min="0" value={order} onChange={e => setOrder(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Contract Address</label>
                                        <input className="form-control" type="text" value={contractAddress} onChange={e => setContractAddress(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Owner</label>
                                        <input className="form-control" type="text" value={owner} onChange={e => setOwner(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Owner Address</label>
                                        <input className="form-control" type="text" value={ownerAddress} onChange={e => setOwnerAddress(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Token ID</label>
                                        <input className="form-control" type="text" value={tokenID} onChange={e => setTokenID(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Network</label>
                                        <input className="form-control" type="text" value={network} onChange={e => setNetwork(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>NYM Power Bonus</label>
                                        <input className="form-control" type="text" value={NYMPowerBonus} onChange={e => setNYMPowerBonus(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Creator</label>
                                        <input className="form-control" type="text" value={creator} onChange={e => setCreator(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Model Id</label>
                                        <input className="form-control" type="text" value={modelId} onChange={e => setModelId(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <hr/>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Collection</label>
                                        <select className="form-control" value={collection} onChange={e => setCollection(e.target.value)}>
                                            <option value='Totems'>
                                                Totems / Cards
                                            </option>
                                            <option value='Martin Tan' >
                                                Martin Tan / Nym Fashion
                                            </option>
                                            <option value='GridZone' >
                                                GridZone / Nym Fashion
                                            </option>
                                            <option value='Legendary' >
                                                Legendary / Rides
                                            </option>
                                            <option value='Classic' >
                                                Classic / Rides
                                            </option>
                                            <option value='Cars' >
                                                Cars / Rides
                                            </option>
                                            <option value='Bikes' >
                                                Bikes / Rides
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Parent Collection</label>
                                        <input className="form-control" type="text" value={parentCollection} onChange={() => false} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <p>Image name format: NAME-SIZE-POSITION <span style={{ fontSize: '70%'}}>(NOSFERATU-SMALL-REAR)</span> </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Front Image</label>
                                        <input className="form-control" type="file" value='' onChange={e => editImageHandler('front', e.target.files[0])} />
                                        {frontImage && <img src={URL.createObjectURL(frontImage)} alt="front" style={previewImgStyle} />}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rear Image</label>
                                        <input className="form-control" type="file" value='' onChange={e => editImageHandler('rear', e.target.files[0])} />
                                        {backImage && <img src={URL.createObjectURL(backImage)} alt="back" style={previewImgStyle} />}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Front Image Small</label>
                                        <input className="form-control" type="file" value='' onChange={e => editImageHandler('front-small', e.target.files[0])} />
                                        {frontImageSmall && <img src={URL.createObjectURL(frontImageSmall)} alt="front" style={previewImgStyle} />}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rear Image Small</label>
                                        <input className="form-control" type="file" value='' onChange={e => editImageHandler('rear-small', e.target.files[0])} />
                                        {backImageSmall && <img src={URL.createObjectURL(backImageSmall)} alt="back" style={previewImgStyle} />}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    {serverMessage && <p>{serverMessage}</p>}
                                    <button type="submit" className="btn btn-primary" style={{ marginTop: '20px'}}>
                                        {editing ? 'Edit' : 'Add'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-2" />
                </div>
            </div>
        </div>
    )
}

export default AddNFT;
