import React from "react";
import DeleteBtn from "./DeleteBtn";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const VideoList = ({videos, onDeleteVideo, onDragVideoEnd}) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                List of videos
            </div>
            <div className="panel-body">
                <div className="table-responsive">

                    <DragDropContext onDragEnd={onDragVideoEnd}>

                            <table className="table table-bordered videos">
                                <thead>
                                <tr>
                                    <th />
                                    <th >Link</th>
                                    <th>Image</th>
                                    <th>Position</th>
                                    <th>Name</th>
                                    <th>Collection</th>
                                    <th>Description</th>
                                    <th />
                                </tr>
                                </thead>
                                <Droppable droppableId="videos">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {videos.map((video, index) => {
                                                const { _id, link, name, description, image, position, collection } = video;
                                                return (
                                                    <Draggable key={_id} draggableId={_id} index={index}>
                                                        {(provided) => (<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <td><i className='fa fa-bars'/></td>
                                                            <td>{link}</td>
                                                            <td >{image}</td>
                                                            <td>{position}</td>
                                                            <td >{name}</td>
                                                            <td >{collection}</td>
                                                            <td >{description}</td>
                                                            <td ><DeleteBtn id={_id} onClickChange={onDeleteVideo} /></td>
                                                        </tr>)}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </table>
                    </DragDropContext>

                </div>
            </div>
        </div>
    )
}

export  default VideoList;
