import React, {useState, useRef} from 'react';

const UserLoginForm = ({loginDataChange, setUsernameRef, setPasswordRef}) => {
    const [ _username, _setUsername ] = useState('');
    const [ _password, _setPassword ] = useState('');
    const username = useRef(_username), password = useRef(_password);

    const setUsername = value => {
        _setUsername(value);
        username.current = value;
    }
    const setPassword = value => {
        _setPassword(value);
        password.current = value;
    }

    const keyPressHandler = e => {
        if(e.charCode === 13) {
            if (!password.current) {
                return;
            }
            const user = username.current, pass = password.current.trim();
            loginDataChange(user, pass);
        }
    }

    return (
        <form>
            <div className="input-wrapper">
                <div className="input-desc">
                    Username
                </div>
                <input ref={setUsernameRef} type="text" className="form-control" value={_username} onChange={e => setUsername(e.target.value)} />
            </div>
            <div className="input-wrapper">
                <div className="input-desc">
                    Password
                </div>
                <input ref={setPasswordRef} type="password" autoComplete="on" className="form-control" value={_password}
                       onChange={e => setPassword(e.target.value)} onKeyPress={keyPressHandler} />
            </div>
        </form>
    )
}

export default UserLoginForm;
