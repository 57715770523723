import React from 'react';

const BaseFaBtn = ({ id, faClassName ,onClickChange }) => {
    const handleClick = () => {
        onClickChange(id);
    }

    return (
        <i onClick={handleClick} className={`fa ${faClassName} fa-2x base-fa`} />
    )
}

export default BaseFaBtn;
