import React, { useEffect, useContext, useState } from 'react';

import { AppContext } from '../context/app-context';

import { url } from '../Constants';
import getDate from '../helpers/getDate';

const Transactions = ({ _id }) => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    console.log(_id)

    const [ transactions, setTransactions ] = useState([]);
    const [ page, setPage ] = useState(0);
    const [ perPage, setPerPage ] = useState(50); 
    const [ haveMore, setHaveMore ] = useState(true);

    useEffect(() => {
        const getTransactions = async () => {
            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/transactions/user/${'60735ffc8e715b1fc94fdd7e'}?page=${page}&perPage=${perPage}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const json = await get.json();
            const { data } = json;

            setTransactions(transactions => {

                return [...transactions, ...data];
            });
        }

        getTransactions();
    }, [ _id, type, page, perPage ]);

    return (
        <div className="panel panel-default">
            <div className="panel-body">
                <h4>Transactions</h4>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction, index) => {
                                const { _id, type, amount, description, createdAt } = transaction;

                                return (
                                    <tr key={_id}>
                                        <td>{type}</td>
                                        <td>{description}</td>
                                        <td>{amount}</td>
                                        <td>{getDate(createdAt)}</td>
                                    </tr>
                                )
                                })}
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Transactions;