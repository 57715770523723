import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';
import {fetchPostJson} from "../helpers/fetchWrapper";
import {getAppTypesRequest, getVersionsRequest} from "../effects/Version";
import {CustomSelect} from "../components/CustomSelect";
import LoadingBtn, {LoadingBtnType} from "../components/LoadingBtn";
const initVersionNumber = 1;

const Versions = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ versions, setVersions ] = useState([]);
    const [ appTypes, setAppTypes ] = useState([]);

    const [ versionDescription, setVersionDescription ] = useState('');
    const [ versionNumber, setVersionNumber ] = useState(initVersionNumber);
    const [ appType, setAppType ] = useState('');

    useEffect(() => {
        const getVersions = async () => {
            const data = await getVersionsRequest(type);
            setVersions(data);
        }

        const getAppVersionTypes = async () => {
            const data = await getAppTypesRequest(type);
            setAppTypes(data);
        }

        getVersions();
        getAppVersionTypes();
    }, [type]);

    const addVersion = async (e) => {
        if (!versionDescription) {
            return alert('Please fill all fields');
        }

        let currentVersionNumber = 1;
        let currentAppType = appType || appTypes[0];
        const parsedVersionNumber = parseFloat(versionNumber, 10);
        // Update version with new one
        if (versions.length > 0 && initVersionNumber === parsedVersionNumber) {
            const currentVersionInfo = versions.find((ver) => ver.appType === currentAppType);
            if (currentVersionInfo.number != null) {
                currentVersionNumber = currentVersionInfo.number + 0.1;
            }
        } else {
            currentVersionNumber = parsedVersionNumber;
        }

        try {
            const result = await addVersionRequest(currentVersionNumber, versionDescription, currentAppType);
            // If there was no error reload page
            if (result != null) {
                window.location.reload();
            }
        } catch(err) {
            alert('Problem adding new version, check your version number')
        }
    }

    const addVersionRequest = async (number, description, appType) => {

        const response = await fetchPostJson(type, 'version/add',  {
            number, description, type: appType
        });

        if (!response) {
            alert(`Can't add new version: ${response.message}`);
            return null;
        }

        alert('Added new version of app so users are notified of change');
        return response;
    }

    const addTypeInfos = (data) => {
        setAppType(data);
    }

    return (
        <Page title='Updates'>
            <form className="version-form">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Description</label>
                            <input type="text" id="versionDescription" placeholder="Description" className="form-control"
                                   onChange={e => setVersionDescription(e.target.value)} value={versionDescription} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Version</label>
                            <input type="text" placeholder="Version" className="form-control"
                                   onChange={e => setVersionNumber(e.target.value)} value={versionNumber} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <CustomSelect label={'App type'} items={appTypes} addItemChange={addTypeInfos}
                                          selectedItems={appType} />
                        </div>
                    </div>
                    <LoadingBtn title={'Add update'} width={4} loading={false}
                                type={LoadingBtnType.Primary} onClickChange={addVersion} />
                </div>
            </form>

            <div className="panel panel-default">
                <div className="panel-heading">
                    List of updates
                </div>
                <div className="panel-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Description</th>
                                    <th>App type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {versions.map((version) => {
                                    const { _id, number, description, appType } = version;
                                    return (
                                        <tr key={_id}>
                                            <td>{number}</td>
                                            <td>{description}</td>
                                            <td>{appType}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Versions;
