import React, { useState, useEffect } from 'react';

import Page from '../components/Page';
import AddNFT from '../components/AddNFT';
import NFTList from '../components/NFTList';
import NFTTransactions from '../components/NFTTransactions';

const Marketplace = props => {

    const routerState = props.location.state;

    const getActive = () => {
        setActive(routerState && routerState.active ? routerState.active : 'add');
    };

    const [ active, setActive ] = useState('add');
    useEffect(getActive, [routerState]);

    return (
        <Page title='Marketplace'>
            {active === 'add' && <AddNFT state={routerState} />}
            {active === 'list' && <NFTList />}
            {active === 'transactions' && <NFTTransactions />}
        </Page>
    )
}

export default Marketplace;