import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import userImg from '../assets/img/find_user.png';

const Sidebar = () => {

    const [ marketplaceMenu, setMarketplaceMenu ] = useState(false);

    return (
        <nav className="navbar-default navbar-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="nav" id="main-menu">
                    <li className="text-center">
                        <img src={userImg} className="user-image img-responsive" alt="user" />
                    </li>
                    <li>
                        <Link to='/users'><i className="fa fa-user fa-3x fa-fw"></i>Users</Link>
                    </li>
                    <li>
                        <Link to='/invites'><i className="fa fa-desktop fa-3x fa-fw"></i> Invites</Link>
                    </li>
                    <li>
                        <Link to='/subscriptions'><i className="fa fa-qrcode fa-3x fa-fw"></i> Subscriptions</Link>
                    </li>
                    <li className="active" onClick={() => setMarketplaceMenu(!marketplaceMenu)}>
                        <div className="holder" style={{ padding: '15px 10px', color: '#fff', cursor: 'pointer' }}>
                            <i className="fa fa-shopping-cart fa-3x fa-fw" style={{ marginRight: '10px'}} /> Marketplace {/* <span className="fa arrow"></span> */}
                        </div>
                        <ul className={`nav nav-second-level collapse ${marketplaceMenu ? 'in' : ""}`} style={{ height: marketplaceMenu ? 'auto' : '0px' }}>
                            <li>
                                <Link to={{
                                    pathname: '/marketplace',
                                    state: {
                                        active: 'add',
                                        edit: false
                                    }
                                }}>Add NFT</Link>
                            </li>
                            <li>
                                <Link to={{
                                    pathname: '/marketplace',
                                    state: {
                                        active: 'list'
                                    }
                                }}>List of NFTs</Link>
                            </li>
                            <li>
                                <Link to={{
                                    pathname: '/marketplace',
                                    state: {
                                        active: 'transactions'
                                    }
                                }}>
                                    Transactions
                                </Link>
                            </li>
                            <li>
                                <Link to='/nftRewards'><span className="link-name">NFT Rewards</span></Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to='/metaverse'><i className="fa fa-file fa-3x fa-fw" /> Metaverse</Link>
                    </li>
                    <li>
                        <Link to='/versions'><i className="fa fa-code fa-3x fa-fw"/> Updates</Link>
                    </li>
                    <li>
                        <Link to='/bugs'><i className="fa fa-bug fa-3x fa-fw"/> Bugs</Link>
                    </li>
                    <li>
                        <Link to='/videos'><i className="fa fa-video-camera fa-3x fa-fw"/> <span className="link-name">Videos</span></Link>
                    </li>

                    <li>
                        <Link to='/promotions'><i className="fa fa-twitter fa-3x fa-fw"/> <span className="link-name">Promotions</span></Link>
                    </li>

                    {/* <li>
                        <Link to='/locations'><i className="fa fa-gamepad fa-3x fa-fw"/> <span className="link-name">Games</span></Link>
                    </li> */}
                    <li>
                        <Link to='/settings'><i className="fa fa-gamepad fa-3x fa-fw"/> <span className="link-name">Game Settings</span></Link>
                    </li>
                    <li>
                        <Link to='/daily-quests'><i className="fa fa-tasks fa-3x fa-fw"/> <span className="link-name">Daily Quests</span></Link>

                    </li>
                    {/* <li>
                        <Link to='/challenges'><i className="fa fa-bolt fa-3x fa-fw"/> <span className="link-name">Challenges</span></Link>

                    </li> */}
                    <li>
                        <Link to='/rooms'><i className="fa fa-home fa-3x fa-fw"/> <span className="link-name">Rooms</span></Link>

                    </li>
                    <li>
                        <Link to='/radio'><i className="fa fa-bullseye fa-3x fa-fw"/> <span className="link-name">Radio</span></Link>

                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Sidebar;
