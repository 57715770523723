const ArrayHelper = {
    insert: function (elements, newItem) {
        return [
            ...elements,
            newItem
        ];
    },
    removeAtIndex: function (elements, removeAt) {
        return [
            ...elements.slice(0, removeAt),
            ...elements.slice(removeAt + 1)
        ];
    },
    insertOrRemove: function (elements, newItem) {
        const indexOfNewItem = elements.indexOf(newItem);
        if (indexOfNewItem > -1) {
            return this.removeAtIndex(elements, indexOfNewItem);
        } else {
            return this.insert(elements, newItem);
        }
    },
    updateAtIndex: function (elements, newItem, index) {
        return Object.assign([...elements], { [index]: newItem});
    }
}

export default  ArrayHelper;