import { createContext, useEffect, useState, useContext, ReactNode, useCallback, Dispatch, SetStateAction, useMemo } from 'react';

import { AppContext } from './app-context';

import { url } from '../Constants';

const SettingsContext = createContext(null);

const SettingsProvider = ({ children }) => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type, isAuth } = state;

    const [ settings, setSettings ] = useState(undefined);

    useEffect(() => {

        if(!isAuth) return;

        const token = localStorage.getItem('pcAdmin');

        const getSettings = async () => {            

            const settings = await fetch(`${url(type)}/game-settings`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await settings.json();
    
            const { data } = json;

            setSettings(data);
        }

        getSettings();

        
    }, [ isAuth, type ]);
    

    return (
        <SettingsContext.Provider value={{
            settings,
            setSettings
        }}>
            {children}
        </SettingsContext.Provider>
    )
}

const useSettings = () => useContext(SettingsContext)

export { SettingsContext, SettingsProvider, useSettings }