import {authFetch, fetchDelete, fetchPostJson, fetchPut} from "../helpers/fetchWrapper";

export const getVideosRequest = async (type) => {
    const json = await authFetch(type, 'video/all', 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const getVideoGroupNamesRequest = async (type) => {
    const json = await authFetch(type, 'video/groupNames', 'GET');

    if (!json.success) {
        return;
    }

    return (json.data);
}

export const uploadVideoRequest = async (type, file) => {
    const formData = new FormData();
    formData.append('video', file);

    const response = await authFetch(type, 'video/upload', 'POST', formData);
    if (!response.success) {
        alert(`Can't upload new video: ${response.message}`);
        return null;
    }

    return response.data;
}

export const uploadVideoImageRequest = async (type, file) => {
    const formData = new FormData();
    formData.append('video-image', file);

    const response = await authFetch(type, 'video/upload/image', 'POST', formData);
    if (!response.success) {
        alert(`Can't upload new video image: ${response.message}`);
        return null;
    }

    return response.data;
}

export const addVideoRequest = async ({type, name, description, url, image, sourceUrl}) => {
    const payload = ({
         url, name, description, image, sourceUrl
    });

    const response = await fetchPostJson(type, 'video/add', payload);
    if (!response.success) {
        alert(`Can't add new video: ${response.message}`);
        return null;
    }

    alert('Added new video to app');
    return response.data;
}

export const getVideoStreamingLink = async (type, name) => {
    const payload = ({
        name
    });

    const response = await fetchPostJson(type, 'video/upload/streaming-link', payload);
    if (!response.success) {
        alert(`Can't get video streaming link: ${response.message}`);
        return null;
    }

    return response.data;
}


export const deleteVideoRequest = async ({type, id}) => {
    const response = await fetchDelete(type, `video/delete/${id}`);
    if (!response.success) {
        alert(`Can't delete video: ${response.message}`);
        return null;
    }

    alert('Deleted selected video');
    return response.data;
}

export const editMultipleVideoRequest = async ({type, videos}) => {
    const response = await fetchPut(type, `video/edit/bulk`, JSON.stringify(videos));
    if (!response.success) {
        alert(`Can't update videos: ${response.message}`);
        return null;
    }

    alert('Updated video positions');
    return response.data;
}
