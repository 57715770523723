
export const LoaderSize = Object.freeze({ Xs:"Xs", Sm:"Sm",
    Md: "Md", Xl: "Xl"});

export const Loader = ({size}) => {
    const getSize = (size) => {
        switch (size) {
            case LoaderSize.Xs:
                return {width: 20, height: 20};
            case LoaderSize.Sm:
                return {width: 35, height: 35};
            case LoaderSize.Md:
                return {width: 50, height: 50};
            case LoaderSize.Xl:
                return {width: 100, height: 100};
            default:
                return {width: 0, height: 0};
        }
    }

    // Assign default
    if (!size) {
        size = LoaderSize.Sm;
    }
    const loaderSize = getSize(size);
    return (<div className='loader' style={{width: `${loaderSize.width}px`, height: `${loaderSize.height}px`}}/>);
}


