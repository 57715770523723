import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/app-context';

import Page from '../components/Page';

import { url } from '../Constants';
import getDate from '../helpers/getDate';

const Bugs = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ bugs, setBugs ] = useState([]);

    useEffect(() => {
        const getBugs = async () => {
            const bugs = await fetch(`${url(type)}/bugs/all`);
    
            const json = await bugs.json();
    
            const { data } = json;

            setBugs(data);
        }

        getBugs();

        
    }, [type]);

    return (
        <Page title='Bugs'>
            <div className="panel panel-default">
                <div className="panel-heading">
                    List of bugs
                </div>
                <div className="panel-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>OS</th>
                                    <th>Browser</th>
                                    <th>Description</th>
                                    <th>Reported</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bugs.map(bug => {
                                    const{ _id, user, os, browser, description, createdAt } = bug;
                                    
                                    return (
                                        <tr key={_id}>
                                            <th style={{ width: '180px'}}>{user}</th>
                                            <th style={{ width: '120px'}}>{os}</th>
                                            <th>{browser}</th>
                                            <th>{description}</th>
                                            <th style={{ width: '120px'}}>
                                                {getDate(createdAt)}
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Bugs;