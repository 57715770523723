import React from "react";
import ArrayHelper from "../helpers/array";

export const CustomSelect = ({label, selectedItems, addItemChange, items, isMultiple}) => {
    const onAddItem = (e) => {
        if (isMultiple)
            addItemChange(ArrayHelper.insertOrRemove(selectedItems, e.target.value));
        else
            addItemChange(e.target.value);
    }

    return (
        <div className="form-group">
            <label>{label}</label>
            <select className="form-control" value={selectedItems} multiple={isMultiple} onChange={onAddItem}>
                {items && items.map((item) => {
                    return <option key={item} value={item}>{item}</option>
                })}
            </select>
        </div>)
}