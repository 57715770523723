import React, { useState, useEffect } from 'react';

import Page from '../components/Page';
import {getRadioStationNamesRequest, getRadioTracksRequest} from '../effects/Radio'
import { useRadioReducer } from '../effects/reducers/RadioReducer';
import RadioAddEdit from "../components/RadioAddEdit";
import RadioList from "../components/RadioList";

const Radio = () => {
    const { fetching, addTrack, deleteTrack, type } = useRadioReducer();

    const [ tracks, setTracks ] = useState([]);
    const [ groupNames, setGroupNames ] = useState([]);
    const [ videoName, setVideoName ] = useState('');
    const [ videoFile, setVideoFile ] = useState(null);
    const [ stationName, setStationName ] = useState('');

    const getTracks = async () => {
        if (!stationName) return;

        const videos = await getRadioTracksRequest(type);
        setTracks(videos);
    }

    const getStations = async  () => {
        const videos = await getRadioStationNamesRequest(type);
        setGroupNames(videos);
    }

    useEffect(() => {
        //getTracks();
        getStations();
    }, [type]);

    useEffect(() => {
        getTracks();
    }, [stationName])

    const onAdd = async () => {
        const result = await addTrack(videoFile, stationName, videoName);

        // If there was no error reload page
        if (result != null) {
            window.location.reload();
        }
    }

    const onDelete = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete radio station track?');
        if (!confirmed) {
            return;
        }

        const result = await deleteTrack(id);
        if (result != null) {
            window.location.reload();
        }
    }

    return (
        <Page title='Videos'>
            <RadioAddEdit fetching={fetching} onAddRadioEntry={onAdd} setVideoName={setVideoName} videoName={videoName}
                          setFile={setVideoFile} stationName={stationName} setStationName={setStationName}
                          stations={groupNames} />

            <RadioList tracks={tracks} onDeleteVideo={onDelete} />
        </Page>
    )
}

export default Radio;
