import React, { useContext } from 'react';
import { AppContext } from '../context/app-context';

import image from '../assets/img/nexus.jpg';

import { POWERCORE, GRID } from '../Constants';
import UserLoginForm from "../components/UserLoginForm";

const Login = () => {
    const appContext = useContext(AppContext);
    const { state, login, changeType } = appContext;
    const { type, fetching } = state;

    return (
        <div className="login" style={{ backgroundImage: `url('${image}')`}}>
            {fetching &&  <div className="login-overlay"/> }
            <div className="login-nav">
                <div className="form-group" style={{ marginRight: '10px', marginBlock: 0}}>
                    <label className="checkbox-inline">
                        <input type="checkbox" checked={type === POWERCORE} onChange={() => changeType(POWERCORE)} /> Powercore
                    </label>
                    <label className="checkbox-inline">
                        <input type="checkbox" checked={type === GRID} onChange={() => changeType(GRID)} /> Grid
                    </label>
                </div>
            </div>
            <div className="login-wrapper">
                <div className="heading-wrapper">
                    Enter Nexus
                </div>
                <UserLoginForm className={'input-data-container'} loginDataChange={login} />
                {/*<form>
                    <div className="input-wrapper">
                        <div className="input-desc">
                            Username
                        </div>
                        <input type="text" className="form-control" value={_username} onChange={e => setUsername(e.target.value)} />
                    </div>
                    <div className="input-wrapper">
                        <div className="input-desc">
                            Password
                        </div>
                        <input type="password" autoComplete="on" className="form-control" value={_password} onChange={e => setPassword(e.target.value)} onKeyPress={keyPressHandler} />
                    </div>
                </form>*/}
            </div>
        </div>
    )
}

export default Login;
