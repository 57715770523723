
const PromotionDetailsEligibleUsers = ({users}) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                List of eligible users for promotion
            </div>
            <div className="panel-body">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Email</th>
                            <th>Public address</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users ? users.map((item) => {
                            const { _id, email, publicAddress } = item;
                            return (
                                <tr key={_id}>
                                    <th>{_id}</th>
                                    <th>{email}</th>
                                    <th>{publicAddress}</th>
                                </tr>
                            )
                        }): null}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export  default PromotionDetailsEligibleUsers;
