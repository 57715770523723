import React, { useState, useEffect, useContext } from 'react';
import Page from '../components/Page';

import { AppContext } from '../context/app-context';

import { url } from '../Constants';

import InnerTable from '../components/Rooms/InnerTable';

const Rooms = () => {

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { type } = state;

    const [ serverRooms, setServerRooms ] = useState([]); // original array from server, if canceled will be updated from here
    const [ rooms, setRooms ] = useState([]);
    const [ serverTime, setServerTime ] = useState(undefined);
    const [ maps, setMaps ] = useState([]);

    useEffect(() => {
        const getRooms = async () => {

            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/daily-rooms`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            const json = await get.json();

            const { data } = json;

            if(data) {
                const mapDays = [...data].map(day => {

                    const mapRooms = day.rooms.map(room => {
                        return {
                            ...room,
                            editing: false
                        }
                    })

                    return {
                        ...day,
                        rooms: mapRooms,
                        editing: false
                    }
                });

                setRooms(mapDays);
                setServerRooms(mapDays);
                setServerTime(new Date(data.serverTime));
            }
        }

        getRooms();
    }, [ type ]);

    useEffect(() => {
        const getMaps = async () => {
            const token = localStorage.getItem('pcAdmin');

            const get = await fetch(`${url(type)}/maps`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            const json = await get.json();

            if(json.success && json.data) {
                setMaps(json.data);
            }
        }

        getMaps();
    }, [ type ]);

    const makeDate = (d) => {
        const date = new Date(d);

        const day = date.getDay();
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const month = date.getMonth();
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        return `${days[day]}, ${date.getDate()} ${months[month]}`;
    }

    const editDayHandler = (_id) => {
        setRooms(rooms => {
            return [...rooms].map(room => {
                return {
                    ...room,
                    editing: room._id === _id ? true : room.editing
                }
            });
        })
    }

    const editQuestHandler = (_id) => {
        setRooms(rooms => {
            
            return [...rooms].map(room => {

                const mapRooms = [...room.rooms].map(r => {

                    return {
                        ...r,
                        editing: r._id === _id ? !r.editing : r.editing
                    }
                });

                return {
                    ...room,
                    rooms: mapRooms
                }
            });
        })
    }

    const cancelHandler = (_id) => { // cancel editing and update from original array
        const findServerQuest = [...serverRooms].find(quest => quest._id === _id);

        setRooms(rooms => {
            return [...rooms].map(room => {
                return room._id === _id ? findServerQuest : room
            })
        });
    }

    const [ copiedRooms, setCopiedRooms ] = useState([]);

    const copyHandler = (rooms) => {
        const map = [...rooms].map(room => {
            return {
                ...room,
                copied: true
            }
        });
        setCopiedRooms(map);
    }

    const pasteHandler = (_id) => {
        setRooms(rooms => {
            return [...rooms].map(room => {
                return {
                    ...room,
                    rooms: room._id === _id ? copiedRooms : room.rooms
                }
            })
        })
    }

    const updateRoomHandler = async (room) => {

        const token = localStorage.getItem('pcAdmin');

        const isCopied = room.rooms.find(room => room.copied);

        if(!isCopied) { // adding one new room
            const maprooms = [...room.rooms].map(room => {
                return {
                    ...room,
                    map: room.map._id
                }
            });
    
            const update = await fetch(`${url(type)}/daily-rooms`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _id: room._id,
                    rooms: maprooms
                })
            });
    
            const json = await update.json();
    
            if(json.data) {
    
                console.log(json.data)
    
                setRooms(rooms => {
                    return [...rooms].map(room => {
                        return room._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : room
                    })
                });
    
                setServerRooms(rooms => {
                    return [...rooms].map(room => {
                        return room._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : room
                    })
                });
            }
        } else { // adding multiple rooms
            const mapRooms = [...room.rooms].map(room => {
                return {
                    ...room,
                    _id: undefined,
                    map: room.map._id
                }
            }); 

            const paste = await fetch(`${url(type)}/daily-rooms/paste`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _id: room._id,
                    rooms: mapRooms
                })
            });
    
            const json = await paste.json();

            if(json.success) {
                setRooms(rooms => {
                    return [...rooms].map(room => {
                        return room._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : room
                    })
                });
    
                setServerRooms(rooms => {
                    return [...rooms].map(room => {
                        return room._id === json.data._id ? {
                            ...json.data,
                            editing: false
                        } : room
                    })
                });
            }

            // console.log(json)
        }
        
    }

    const changeInputHandler = (value, _id, type) => {

        setRooms(rooms => {
            return [...rooms].map(day => {

                const mapRooms = [...day.rooms].map(r => {

                    const currentInput = r._id === _id;

                    const getNumberValue = () => {
    
                        if(value === '') {
                            return 0;
                        } else {
                            const parseValue = parseFloat(value);
                            return !isNaN(parseValue) ? parseValue : 0;
                        }
                    }
    
                    if((currentInput && type === 'map')) {
                        console.log(value, r.map)
                    }

                    const getMapObject = () => {
                        const findMap = maps.find(map => map._id === value);
                        return findMap
                    }

                    return {
                        ...r,
                        room: (currentInput && type === 'room') ? getNumberValue() : r.room,
                        roomType: (currentInput && type === 'roomType') ? getNumberValue() : r.roomType, 
                        capacity: (currentInput && type === 'capacity') ? getNumberValue() : r.capacity, 
                        music: (currentInput && type === 'music') ? getNumberValue() : r.music, 
                        xpRequirement: (currentInput && type === 'xpRequirement') ? getNumberValue() : r.xpRequirement, 
                        vipPercentage: (currentInput && type === 'vipPercentage') ? getNumberValue() : r.vipPercentage, 
                        vipRequirement: (currentInput && type === 'vipRequirement') ? value === 'true' ? true : false : r.vipRequirement, 
                        ticketPrice: (currentInput && type === 'ticketPrice') ? getNumberValue() : r.ticketPrice, 
                        map: (currentInput && type === 'map') ? getMapObject() : r.map,
                        car: (currentInput && type === 'car') ? value : r.car,
                        laps: (currentInput && type === 'laps') ? getNumberValue() : r.laps,
                        overallTime: (currentInput && type === 'overallTime') ? getNumberValue() : r.overallTime,
                        totalAttempts: (currentInput && type === 'totalAttempts') ? getNumberValue() : r.totalAttempts,
                        maxNumberOfCollectibles: (currentInput && type === 'maxNumberOfCollectibles') ? getNumberValue() : r.maxNumberOfCollectibles,
                        rewardMultiplier: (currentInput && type === 'rewardMultiplier') ? getNumberValue() : r.rewardMultiplier,
                        color: (currentInput && type === 'color') ? getNumberValue() : r.color,
                        teeShirtImage: (currentInput && type === 'teeShirtImage') ? getNumberValue() : r.teeShirtImage
                    };

                });

                return {
                    ...day,
                    rooms: mapRooms
                }
            })
        });
    }

    const updateMemoryQuestCallback = (newRoom) => { // when adding new quest we will update current quests with new one from server

        // we get latest quest and update its properties
        const latestRoom = {
            ...newRoom.rooms[newRoom.rooms.length - 1],
            editing: false
        };

        
        setRooms(rooms => {
            return [...rooms].map(room => {

                const updatedRooms = newRoom._id === room._id ? [...room.rooms, latestRoom] : [...room.rooms];

                return {
                    ...room,
                    rooms: updatedRooms
                }
            })
        });
        setServerRooms(rooms => {
            return [...rooms].map(room => {

                const updatedRooms = newRoom._id === room._id ? [...room.rooms, latestRoom] : [...room.rooms];

                return {
                    ...room,
                    rooms: updatedRooms
                }
            })
        });
    }

    return (
        <Page
            title='Rooms'
        >
            <div className="panel panel-default">
                <div className="panel-heading" style={{display: 'flex', flexDirection: 'row'}}>
                    
                </div>
                <div className="panel-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rooms.map((room, index) => {

                                    const { _id, day, rooms, editing } = room;

                                    const notActive = () => {
                                        return new Date(day).getTime() <= new Date(serverTime).getTime() ? 'danger' : '';
                                    }

                                    const date = makeDate(day);

                                    const deleteRoom = async (rooms) => {

                                        const thisRoom = {
                                            ...room, // current quest object
                                            rooms // filtered quests from frontend
                                        }

                                        await updateRoomHandler(thisRoom);
                                    }

                                    return (
                                        <>
                                            <tr key={_id} className={notActive()} style={{ cursor: notActive() ? 'not-allowed' : 'default' }}>
                                                <td style={{ padding: '20px 10px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                        <div>
                                                            {date}
                                                        </div>
                                                        <div>
                                                            {!notActive() && (
                                                                <button 
                                                                    className="btn btn-danger" 
                                                                    style={{ marginRight: '10px' }} 
                                                                    onClick={() => editing ? cancelHandler(_id) : editDayHandler(_id)}
                                                                >
                                                                    {editing ? 'Close' : 'Open'}
                                                                </button>
                                                            )}
                                                            {editing && (
                                                                <button 
                                                                    className="btn btn-primary" 
                                                                    style={{ marginRight: '10px' }} 
                                                                    onClick={() => rooms.length > 0 && copyHandler(rooms)}
                                                                    disabled={rooms.length === 0}
                                                                >
                                                                    Copy
                                                                </button>
                                                            )}
                                                            {editing && (
                                                                <button 
                                                                    className="btn btn-info" 
                                                                    style={{ marginRight: '10px' }} 
                                                                    onClick={() => rooms.length === 0 && pasteHandler(_id)}
                                                                    disabled={rooms.length > 0}
                                                                >
                                                                    Paste
                                                                </button>
                                                            )}
                                                            {editing && (
                                                                <button className="btn btn-success" onClick={() => updateRoomHandler(room)}>
                                                                    Update
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {editing && (
                                                <InnerTable 
                                                    rooms={rooms} 
                                                    maps={maps} 
                                                    changeInputHandler={changeInputHandler} 
                                                    editQuestHandler={editQuestHandler} 
                                                    date={date}
                                                    _id={_id}
                                                    addNewCallback={updateMemoryQuestCallback}
                                                    deleteRoom={deleteRoom}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Rooms;